import React, { useState, useRef } from 'react';

import { ExpandMore } from '@mui/icons-material';
import { Button, type ButtonProps } from '@mui/material';

import { combineSx } from '@ivy/lib/styling/sx';

import QuickFilterPopover, {
	type QuickFilterPopoverProps,
} from './QuickFilterPopover';

export interface QuickFilterButtonProps<T>
	extends Omit<ButtonProps, 'onChange' | 'value'> {
	onApply: (nv?: T) => void;
	onDiscard: () => void;
	initialValue: T[keyof T];
	value: T;
	onChange: (nv: T) => void;
	field: keyof T;
	label: string;
	title?: string;
	renderInput: (
		value: T[keyof T],
		onChange: (ev: React.SyntheticEvent, nv: T[keyof T]) => void,
	) => React.ReactNode;
	badgeContent?: number | string;
	footerComponent?: QuickFilterPopoverProps['footerComponent'];
	hideFooter?: QuickFilterPopoverProps['hideFooter'];
	PopoverProps?: Partial<QuickFilterPopoverProps>;
	tooltipContent?: string;
}

const QuickFilterButton = <T,>({
	onApply,
	onDiscard,
	initialValue,
	value,
	onChange,
	field,
	label,
	title,
	tooltipContent,
	renderInput,
	badgeContent,
	footerComponent,
	hideFooter,
	sx,
	PopoverProps,
	...props
}: QuickFilterButtonProps<T>) => {
	const anchorRef = useRef(null);
	const [open, setOpen] = useState(false);

	const handleChange = (ev: React.SyntheticEvent, nv: T[keyof T]) => {
		onChange({
			...value,
			[field]: nv,
		});
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		onDiscard();
	};

	const handleReset = () => {
		onApply({
			...value,
			[field]: initialValue ?? null,
		});
		setOpen(false);
	};

	const handleApply = () => {
		onApply();
		setOpen(false);
	};

	return (
		<>
			<Button
				ref={anchorRef}
				variant='outlined'
				endIcon={<ExpandMore />}
				size='small'
				sx={combineSx(
					{
						color: 'text.primary',
						borderColor: 'divider',
						bgcolor: 'white',
						...(badgeContent
							? {
									color: 'primary.main',
									bgcolor: 'primary.translucent',
									borderColor: 'primary.main',
							  }
							: {}),
					},
					sx,
				)}
				onClick={handleOpen}
				{...props}
			>
				{label}
				{!!badgeContent && ` (${badgeContent})`}
			</Button>

			<QuickFilterPopover
				title={title}
				tooltipContent={tooltipContent}
				anchorEl={anchorRef.current}
				open={open}
				onClose={handleClose}
				onSubmit={handleApply}
				onReset={handleReset}
				footerComponent={footerComponent}
				hideFooter={hideFooter}
				{...PopoverProps}
			>
				{renderInput(value[field], handleChange)}
			</QuickFilterPopover>
		</>
	);
};

export default QuickFilterButton;

import React, { useState, useLayoutEffect, useCallback } from 'react';

import {
	Box,
	SvgIcon,
	type SxProps,
	type Theme,
	Typography,
	useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useElementSize } from 'usehooks-ts';

import { BlobSingleLayer } from '@ivy/components/atoms/Blobs';
import RouteLink from '@ivy/components/atoms/RouteLink';
import { ScrollDownIcon } from '@ivy/components/icons';
import FacilitySearchInput from '@ivy/components/organisms/FacilitySearchInput';
import { PROFESSION2SLUG, type Profession } from '@ivy/constants/clinician';
import { type CompleteLocation } from '@ivy/lib/services/maps';
import { combineSx } from '@ivy/lib/styling/sx';
import { buildInternalLink } from '@ivy/lib/util/route';

import Testimonial from './Testimonial';

const keyframes = {
	'@keyframes landing-load': {
		'0%': {
			transform: 'translateY(60px)',
			opacity: 0,
		},
		'100%': {
			transform: 'translateY(0px)',
			opacity: 1,
		},
	},
};

const generateKeyframes = (timing) => ({
	...keyframes,
	animation: `landing-load 600ms ease-in-out ${timing}ms`,
	animationFillMode: 'forwards',
	transform: 'translateY(60px)',
	opacity: 0,
});

// TODO: fix grdauation cap
const Spiral = ({ ...props }) => {
	return (
		<SvgIcon
			width='915'
			height='863'
			viewBox='0 0 915 863'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				opacity='0.1'
				d='M456.002 281.5C418.502 189.5 186.502 234.383 186.502 429.5C186.502 624.617 346.984 678.202 522.493 678.202C698.002 678.202 858.37 575.375 858.37 342.325C858.37 109.274 664.502 1 413.002 1C161.502 1 1.60171 126.951 1.60156 376.475C1.60142 626 128.18 861.715 486.841 861.715C845.501 861.715 913.912 575.375 913.912 575.375'
				stroke='#1EC86A'
				strokeWidth='1.50113'
				strokeLinecap='round'
			/>
		</SvgIcon>
	);
};
const Search = ({ sx }: { sx?: SxProps<Theme> }) => {
	const navigate = useNavigate();

	const handleSubmit = (location: CompleteLocation, profession: Profession) => {
		navigate({
			pathname:
				'url' in location
					? location.url
					: buildInternalLink(RouteLink.routes.SEARCH_INDEX, {
							profession: PROFESSION2SLUG[profession],
							slug: 'slug' in location ? location.slug! : 'search',
					  }),
			search:
				'url' in location
					? ''
					: `&location=${encodeURIComponent(JSON.stringify(location))}`,
		});
	};

	return (
		<FacilitySearchInput
			mobileBp='sm'
			onSubmit={handleSubmit}
			sx={sx}
			types={['place', 'facility', 'org']}
		/>
	);
};

export interface HeroProps {
	facCount: number;
	sx?: SxProps<Theme>;
	bleed?: number;
}

const Hero = ({ sx, facCount, bleed = 100 }: HeroProps) => {
	const [ref, { height: heroHeight }] = useElementSize();
	const smPlus = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'), {
		noSsr: true,
	});
	const shouldBreak = useMediaQuery(
		(theme: Theme) => theme.breakpoints.up(445),
		{
			noSsr: true,
		},
	);
	const [scrolling, setScrolling] = useState(false);

	useLayoutEffect(() => {
		const handleScroll = () => {
			if (
				document.body.scrollTop > 0 ||
				document.documentElement.scrollTop > 0
			) {
				setScrolling(true);
			} else {
				setScrolling(false);
			}
		};
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [setScrolling]);

	const handleClickScrollDown = useCallback(() => {
		if (scrolling) {
			return;
		}
		window.scrollBy({
			top: heroHeight,
			behavior: 'smooth',
		});
	}, [scrolling, heroHeight]);

	return (
		<Box
			component='section'
			sx={combineSx(
				{
					position: 'relative',
					mt: {
						xs: '-64px',
						mobileMenu: '-104px',
					},
					pt: {
						xs: '64px',
						mobileMenu: '104px',
					},
					pb: `${bleed}px`,
					height: `calc(100vh + ${bleed}px)`,
					minHeight: (smPlus ? 675 : 650) + bleed,
				},
				sx,
			)}
		>
			<Box
				sx={{
					position: 'absolute',
					// Full bleed
					width: '100vw',
					left: '50%',
					right: '50%',
					ml: '-50vw',
					mr: '-50vw',
					top: 0,
					bottom: 0,
				}}
			>
				<Box
					component='img'
					src='https://assets.ivyclinicians.io/images/hero-bg.png'
					sx={{
						height: '100%',
						width: '100%',
						objectFit: 'fill',
					}}
				/>
			</Box>
			<Box
				sx={(theme) => ({
					position: 'relative',
					height: '100%',
					display: 'flex',
					[theme.breakpoints.up('md')]: {
						justifyContent: 'center',
						alignItems: 'center',
					},
				})}
				ref={ref}
			>
				<BlobSingleLayer
					sx={{
						display: {
							xs: 'none',
							sm: 'initial',
						},
						position: 'absolute',
						width: '445.47px',
						height: '376.5px',
						left: '-293.44px',
						top: {
							xs: 'calc(147px - 64px)',
							mobileMenu: 'calc(147px - 104px)',
						},
					}}
				/>
				<Box
					sx={{
						position: 'absolute',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Spiral
						sx={{
							height: '860.72px',
							width: '912.31px',
							maxWidth: '100%',
							maxHeight: '100%',
							fill: 'none',
						}}
					/>
				</Box>
				<Box
					component='img'
					src='https://assets.ivyclinicians.io/images/landing-blob-woman.png'
					sx={(theme) => ({
						display: 'none',
						position: 'absolute',
						...generateKeyframes(600),
						// To test these breakpoints, go to where each begins, then shrink the screen vertically
						// to see if they dodge other elements or hide themselves correctly.
						[theme.breakpoints.between('md', 1065)]: {
							width: '275px',
							display: heroHeight < 815 ? 'none' : 'initial',
							// display: 'initial',
							top: heroHeight > 1000 ? '7.5%' : 0,
							right: 0,
						},
						// mui doesn't recognize numbers, so it doesn't know with which priority it should be applied
						// compared to other breakpoints
						[theme.breakpoints.between(1065, 'xl')]: {
							display: 'initial',
							width: '300px',
							top: heroHeight > 950 ? '7.5%' : 0,
							right: 0,
						},
						[theme.breakpoints.between('xl', 1750)]: {
							display: 'initial',
							width: '328px',
							top: heroHeight > 935 ? '20%' : 0,
							right: 0,
						},
						[theme.breakpoints.up(1750)]: {
							display: 'initial',
							width: '328px',
							top: heroHeight > 935 ? '20%' : 0,
							right: '7.5%',
						},
					})}
				/>
				<Box
					component='img'
					src='https://assets.ivyclinicians.io/images/landing-blob-man.png'
					sx={(theme) => ({
						display: 'none',
						position: 'absolute',
						...generateKeyframes(800),
						[theme.breakpoints.between(1050, 'lg')]: {
							width: '300px',
							display: 'initial',
							left: 0,
							bottom: 0,
						},
						[theme.breakpoints.between('lg', 'xl')]: {
							width: '300px',
							// Have some overlap with search bar on 1200, but OK - face still visible
							display: 'initial',
							left: 0,
							bottom: heroHeight < 940 ? 0 : '5%',
						},
						[theme.breakpoints.between('xl', 1750)]: {
							width: '339px',
							display: 'initial',
							left: 0,
							bottom: '5%',
						},
						[theme.breakpoints.up(1750)]: {
							width: '339px',
							display: 'initial',
							bottom: '10%',
							left: '7.5%',
						},
					})}
				/>
				<Testimonial
					facCount={facCount}
					sx={(theme) => ({
						display: 'none',
						[theme.breakpoints.up('md')]: {
							display: 'flex',
							position: 'absolute',
							bottom: 80,
							right: '7.5%',
							width: '600px',
							...generateKeyframes(1000),
						},
						[theme.breakpoints.up('xl')]: {
							bottom: 20,
						},
					})}
				/>
				<Box
					sx={{
						mt: {
							xs: 4,
							// Position in true center by offsetting the NavBar
							// Use md here, not mobileMenu
							// Move up by 100px for small screens
							md: heroHeight < 720 ? '-254px' : '-104px',
						},
						width: '100%',
						mb: {
							xs: 4,
							md: 0,
						},
						height: {
							// 100% height but add room at bottom for scrolldown
							xs: 'calc(100% - 65px)',
							md: 'auto',
						},
						position: 'relative',
						display: 'flex',
						flexDirection: 'column',
						alignItems: {
							sm: 'center',
						},
					}}
				>
					<Typography
						component='h1'
						variant='h2'
						sx={{
							mb: {
								xs: 4,
								sm: 10,
							},
							textAlign: {
								sm: 'center',
							},
							flex: '0 0 auto',
							...generateKeyframes(0),
						}}
					>
						Find your next
						{shouldBreak ? <br /> : ' '}
						<Box
							component='span'
							sx={{
								color: 'secondary.main',
							}}
						>
							emergency medicine job
						</Box>
						{shouldBreak ? <br /> : ' '}
						in one simple search.
					</Typography>
					<Search
						sx={{
							flex: '0 0 auto',
							width: {
								xs: '100%',
								md: '700px',
								lg: '800px',
							},
							maxWidth: {
								sm: '600px',
								md: 'none',
							},
							...generateKeyframes(200),
						}}
					/>
					<Box
						sx={{
							display: {
								md: 'none',
							},
							my: 4,
							flex: '1 1 0',
							width: '100%',
							background:
								'url(https://assets.ivyclinicians.io/images/landing-blob-man-woman.png)',
							backgroundSize: 'contain',
							backgroundRepeat: 'no-repeat',
							backgroundPosition: 'center',
							...generateKeyframes(600),
						}}
					/>
				</Box>
				<ScrollDownIcon
					sx={{
						position: 'absolute',
						bottom: 16,
						left: '50%',
						transform: 'translateX(-50%)',
						width: '63px',
						height: 'auto',
						color: 'text.icon',
						shapeRendering: 'geometricPrecision',
						opacity: scrolling ? 0 : 0.5,
						transition: 'opacity 0.3s linear',
						...(!scrolling && {
							cursor: 'pointer',
							WebkitTapHighlightColor: 'transparent',
							userSelect: 'none',
							WebkitUserSelect: 'none',
						}),
						animation: 'fade-in 2.4s, move-up-down 1.25s linear infinite',
						'@keyframes fade-in': {
							'0%': {
								opacity: 0,
							},
							'50%': {
								opacity: 0,
							},
						},
						'@keyframes move-up-down': {
							'0%, 100%': {
								bottom: 16,
							},
							'50%': {
								bottom: 28,
							},
						},
					}}
					onClick={handleClickScrollDown}
				/>
			</Box>
		</Box>
	);
};

export default Hero;

import React, { useEffect } from 'react';

import { type ReviewFormValues, steps } from '@ivy/components/forms/ReviewForm';
import FormPopup, {
	type MasterFormPopupProps,
} from '@ivy/components/molecules/FormPopup';
import { useAuthPopup } from '@ivy/components/providers/AuthPopupProvider';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql, getFragmentData, type FragmentType } from '@ivy/gql/types';

const ReviewPopup_FacilityFDoc = gql(/* GraphQL */ `
	fragment ReviewPopup_Facility on facility {
		id
		name
		city
		state
	}
`);

const ReviewPopup_OrganizationFDoc = gql(/* GraphQL */ `
	fragment ReviewPopup_Organization on organization {
		id
		name
	}
`);

interface ReviewPopupProps
	extends Omit<
		MasterFormPopupProps<ReviewFormValues>,
		'steps' | 'defaultValues' | 'extra'
	> {
	facility?: FragmentType<typeof ReviewPopup_FacilityFDoc>;
	organization?: FragmentType<typeof ReviewPopup_OrganizationFDoc>;
}

const ReviewPopup = ({
	open,
	facility: rawFacility,
	organization: rawOrganization,
	onClose,
	...props
}: ReviewPopupProps) => {
	const currAcc = useCurrentAccount();
	const { authPopup, openSignupPopup, openConfirmEmailPopup } = useAuthPopup();
	const facility = getFragmentData(ReviewPopup_FacilityFDoc, rawFacility);
	const organization = getFragmentData(
		ReviewPopup_OrganizationFDoc,
		rawOrganization,
	);

	useEffect(() => {
		if (!open) return;

		if (!currAcc) {
			// Don't show ProfileCompletePopup after signup, go right into this form
			openSignupPopup(true, {
				// Close this form if we fail to authenticate
				onFailure: onClose,
			});
		} else if (!currAcc.isClinician) {
			onClose?.();
		} else if (!currAcc.ci?.confirmedEmail) {
			openConfirmEmailPopup({
				onFailure: onClose,
			});
		}
	}, [open, currAcc, openSignupPopup, openConfirmEmailPopup, onClose]);

	if (
		!currAcc ||
		!currAcc.isClinician ||
		!currAcc.ci?.confirmedEmail ||
		authPopup
	) {
		return null;
	}

	return (
		<FormPopup
			open={open}
			onClose={onClose}
			steps={steps}
			extra={{ fullName: currAcc?.pi?.fullName || '' }}
			displayMobileTitle
			defaultValues={{
				facility: facility
					? {
							id: facility.id,
							name: facility.name,
							city: facility.city,
							state: facility.state,
					  }
					: null,
				org: organization
					? {
							id: organization.id,
							name: organization.name,
					  }
					: null,
			}}
			{...props}
		/>
	);
};

export default ReviewPopup;
export { type ReviewPopupProps };

import React from 'react';

import { Check } from '@mui/icons-material';
import {
	Box,
	CardActionArea,
	Chip,
	Grid,
	Paper,
	Typography,
	type TypographyProps,
} from '@mui/material';

import RouteLink from '@ivy/components/atoms/RouteLink';
import StepPagination from '@ivy/components/molecules/StepPagination';
import { useRedirect } from '@ivy/components/providers/RedirectProvider';
import { type WhitelabelTo } from '@ivy/lib/util/route';

import { type NearbyBaseMapItemObject } from './BaseMap';
import EntityCheckbox, { type MapItemCheckboxObject } from './EntityCheckbox';
import MobileCheckbox from './MobileCheckbox';

export interface NearbyCardDataCaption {
	icon: React.ReactNode;
	label: string;
}

export interface NearbyCardDataObject {
	picture: string;
	distance: number;
	badge?: React.ReactNode;
	title: React.ReactNode;
	subtitle?: React.ReactNode;
	subtitleChipLabel?: React.ReactNode;
	captions?: NearbyCardDataCaption[];
	titleTo?: WhitelabelTo;
	subtitleTo?: WhitelabelTo;
}

export interface NearbyMapListItemObject
	extends NearbyCardDataObject,
		MapItemCheckboxObject {
	id: string;
}

interface NearbyCardProps<T extends NearbyMapListItemObject> {
	entity: T;
	titleTypographyProps?: TypographyProps;
	subtitleTypographyProps?: TypographyProps;
	openInNewTab?: boolean;
	selectable?: boolean;
	onSelect?: (entity: T) => void;
	selected?: { id: string }[];
}

const NearbyCard = <T extends NearbyMapListItemObject>({
	entity,
	titleTypographyProps,
	subtitleTypographyProps,
	openInNewTab = false,
	selectable,
	onSelect,
	selected,
}: NearbyCardProps<T>) => {
	const redirect = useRedirect();
	return (
		<CardActionArea
			component='div'
			sx={{
				p: 1,
				borderRadius: (theme) => `${theme.shape.borderRadius}px`,
				border: (theme) => `1px solid ${theme.palette.divider}`,
			}}
			onClick={
				entity.titleTo
					? () => {
							redirect(entity.titleTo!, {
								openInNewTab,
								state: {
									backNav: {
										target: 'search',
									},
								},
							});
					  }
					: undefined
			}
			data-tr-entity-id={entity.id}
			data-tr-featured={true}
			data-tr-nearby={true}
		>
			<Box
				sx={{
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'stretch',
				}}
			>
				<Box
					sx={{
						position: 'relative',
						height: '100px',
						borderRadius: (theme) => `${theme.shape.borderRadius}px`,
						overflow: 'hidden',
					}}
				>
					<Box
						component='img'
						src={entity.picture}
						sx={{
							height: '100%',
							width: '100%',
							objectFit: 'cover',
						}}
					/>
					<Box
						sx={{
							display:
								selectable && selected?.some((el) => el.id === entity.id)
									? 'flex'
									: 'none',
							justifyContent: 'center',
							alignItems: 'center',
							position: 'absolute',
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
							bgcolor: 'primary.main',
							opacity: '0.7',
							color: 'primary.contrastText',
						}}
					>
						<Check
							sx={{
								height: '33%',
								width: 'auto',
							}}
						/>
					</Box>
					{!!entity.badge && (
						<Box
							sx={{
								position: 'absolute',
								top: 0,
								right: 0,
								m: 1,
							}}
						>
							{entity.badge}
						</Box>
					)}
					{selectable && (
						// Put this after the badge in case the badge is too large, this will sit on top
						<Box
							sx={{
								position: 'absolute',
								top: 0,
								left: 0,
								// Add buffer room to prevent mouse clicks on entity card
								p: 1,
							}}
						>
							<EntityCheckbox
								CheckboxComponent={MobileCheckbox}
								entity={entity}
								WrapperComponent={Paper}
								checked={!!selected?.some((el) => el.id === entity.id)}
								onClick={
									onSelect
										? (ev) => {
												ev.stopPropagation();
												onSelect(entity);
										  }
										: undefined
								}
							/>
						</Box>
					)}
				</Box>
				<Box mt={1}>
					<Typography
						variant='body1'
						fontWeight='bold'
						{...titleTypographyProps}
						noWrap
					>
						{entity.titleTo ? (
							<RouteLink
								underline='none'
								color='inherit'
								onClick={(e) => e.stopPropagation()}
								to={entity.titleTo}
								openInNewTab={openInNewTab}
								state={{
									backNav: {
										target: 'search',
									},
								}}
							>
								{entity.title}
							</RouteLink>
						) : (
							entity.title
						)}
					</Typography>
					{!!entity.subtitle && (
						<Box
							sx={{
								display: 'flex',
								overflow: 'hidden',
							}}
						>
							<Typography variant='body1' {...subtitleTypographyProps} noWrap>
								{entity.subtitleTo ? (
									<RouteLink
										onClick={(e) => e.stopPropagation()}
										to={entity.subtitleTo}
										openInNewTab={openInNewTab}
										state={{
											backNav: {
												target: 'search',
											},
										}}
									>
										{entity.subtitle}
									</RouteLink>
								) : (
									entity.subtitle
								)}
							</Typography>
							{entity.subtitleChipLabel && (
								<Chip
									size='small'
									variant='outlined'
									label={entity.subtitleChipLabel}
									sx={(theme) => ({
										position: 'relative',
										ml: 1,
										flex: '0 0 auto',
										height: 'auto',
										'& .MuiChip-label': {
											...theme.typography.caption,
											px: 1,
											position: 'relative',
										},
									})}
								/>
							)}
						</Box>
					)}
					{!!entity.captions?.length && (
						<Box>
							{entity.captions.map((el, idx) => (
								<Box
									key={idx}
									sx={{
										display: 'flex',
										alignItems: 'center',
										color: 'text.icon',
									}}
								>
									<Box>{el.icon}</Box>
									<Typography variant='caption' color='inherit' noWrap>
										{el.label}
									</Typography>
								</Box>
							))}
						</Box>
					)}
				</Box>
			</Box>
		</CardActionArea>
	);
};

export type NearbyCarouselProps<T extends NearbyBaseMapItemObject> = Pick<
	NearbyCardProps<T>,
	'openInNewTab' | 'selectable' | 'onSelect' | 'selected'
> & {
	title?: string;
	nearbyEntities: T[];
	page: number;
	onChangePage: (page: number) => void;
	pageSize: number;
	numRows?: number;
};

const NearbyCarousel = <T extends NearbyBaseMapItemObject>({
	title = 'Nearby Results',
	nearbyEntities,
	page,
	pageSize,
	onChangePage,
	numRows = 1,
	...props
}: NearbyCarouselProps<T>) => {
	return (
		<Box
			sx={{
				bgcolor: 'light3.main',
				py: 1,
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Typography
				variant='body2'
				sx={{
					px: 2,
					fontWeight: 'bold',
				}}
			>
				{title}
			</Typography>
			<Grid
				container
				wrap='wrap'
				spacing={1}
				sx={{
					px: 1,
				}}
			>
				{nearbyEntities
					.slice(page * pageSize, (page + 1) * pageSize)
					.map((el, idx) => (
						<Grid
							key={`${el?.id}-${idx}`}
							item
							xs={12 / Math.ceil(pageSize / numRows)}
						>
							<NearbyCard entity={el} {...props} />
						</Grid>
					))}
			</Grid>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'flex-end',
					mt: 'auto',
					pt: 1,
					px: 2,
				}}
			>
				<StepPagination
					disableFirstLast
					count={nearbyEntities.length}
					pageSize={pageSize}
					page={page}
					onChangePage={onChangePage}
				/>
			</Box>
		</Box>
	);
};

export default NearbyCarousel;

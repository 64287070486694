export class AppError extends Error {
	constructor(message?: string) {
		// https://stackoverflow.com/questions/41102060/typescript-extending-error-class/48342359#48342359
		// 'Error' breaks prototype chain here
		super(message);

		// restore prototype chain
		const actualProto = new.target.prototype;

		if (Object.setPrototypeOf) {
			Object.setPrototypeOf(this, actualProto);
		} else {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(this as any).__proto__ = actualProto;
		}
	}
}

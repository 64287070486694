import { add as addDuration } from 'date-fns';

export const yearsFromNow = (years) => {
	const thisYear = new Date().getFullYear();
	return thisYear + years;
};

export const dateFromDatetime = (value) => {
	return new Date(value.getFullYear(), value.getMonth(), value.getDate());
};

export const todayDate = () => {
	const now = new Date();
	return dateFromDatetime(now);
};

export const dateFromNow = (options) => {
	/*
	years	Amount of years to be added
	months	Amount of months to be added
	weeks	Amount of weeks to be added
	days	Amount of days to be added
	hours	Amount of hours to be added
	minutes	Amount of minutes to be added
	seconds	Amount of seconds to be added
	*/
	if (!options) {
		return todayDate();
	}
	return addDuration(todayDate(), options);
};

import React, { useMemo } from 'react';

import { Info } from '@mui/icons-material';
import {
	Box,
	type BoxProps,
	Grid,
	Typography,
	type Theme,
	type SxProps,
} from '@mui/material';

import BlobIconWrapper from '@ivy/components/molecules/BlobIconWrapper';
import LabeledTypography, {
	type LabeledTypographyProps,
} from '@ivy/components/molecules/LabeledTypography';
import { type ApplyToJobButtonProps } from '@ivy/components/organisms/ApplyToJobButton';
import BannerAd from '@ivy/components/organisms/BannerAd';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql, getFragmentData, type FragmentType } from '@ivy/gql/types';
import { type FacilityFeatures_FacilityFragment } from '@ivy/gql/types/graphql';
import { oxfordJoin } from '@ivy/lib/formatting/string';

import {
	type FacilitySectionConfig,
	type FacilitySectionItem,
	getFacilityFields,
} from './facilityFields';

const UNKNOWN = 'Unknown';

interface FeatureProps
	extends Omit<LabeledTypographyProps, 'label' | 'text'>,
		FacilitySectionItem {
	facility: FacilityFeatures_FacilityFragment;
}

const Feature = ({
	facility,
	label,
	getValue,
	isFeatured = () => true,
	customIcon,
	formatter = (val) => val,
	...props
}: FeatureProps) => {
	const value = getValue(facility);
	const featured = isFeatured(value);

	return (
		<LabeledTypography
			icon={<BlobIconWrapper icon={customIcon} />}
			label={label}
			text={featured !== null ? formatter(value, facility) : UNKNOWN}
			disabled={featured === null}
			textProps={{
				fontWeight: 'bold',
				whiteSpace: 'pre-wrap',
			}}
			{...props}
		/>
	);
};

interface FeatureSetProps extends FacilitySectionConfig {
	facility: FacilityFeatures_FacilityFragment;
	sx?: SxProps<Theme>;
	showDisclaimer?: boolean;
}

const FeatureSet = React.forwardRef<HTMLDivElement, FeatureSetProps>(
	({ facility, section, items, sx, showDisclaimer = false }, ref) => {
		const display = items.some(
			(el) => !el.isFeatured || el.isFeatured(el.getValue(facility)),
		);

		return (
			<Box sx={sx} ref={ref}>
				<Typography
					component='h2'
					variant='h5'
					sx={{
						opacity: !display ? 0.4 : undefined,
					}}
				>
					{section} {!display && '(Not Available)'}
				</Typography>
				{showDisclaimer && !!facility.ahaFacility?.units?.length && (
					<Typography
						variant='body2'
						sx={{
							p: 2,
							color: 'text.icon',
							bgcolor: 'light4.main',
							borderRadius: (theme) => `${theme.shape.borderRadius}px`,
							width: 'fit-content',
							maxWidth: '100%',
							mt: {
								xs: 3,
								md: 5,
							},
						}}
					>
						<Info
							fontSize='small'
							sx={{
								position: 'relative',
								top: '5px',
							}}
						/>{' '}
						Data for this facility includes information for{' '}
						{oxfordJoin(facility.ahaFacility.rollups)}.
					</Typography>
				)}
				{display && (
					// Note there is a negative 4 margin here, so we assign it to 1 instead
					<Grid container spacing={4} mt={1}>
						{items.map((item) => (
							<Grid item key={item.label} xs={12} md={6} lg={4}>
								<Feature facility={facility} {...item} />
							</Grid>
						))}
					</Grid>
				)}
			</Box>
		);
	},
);

const FacilityFeatures_FacilityFDoc = gql(/* GraphQL */ `
	fragment FacilityFeatures_Facility on facility {
		id
		name
		state
		pedTraumaLvl: ped_trauma_lvl
		freestandingEr: freestanding_er
		residency
		strokeCerts: stroke_certs {
			id
			level
		}
		ehrs {
			id
			name
		}
		cmsFacility: cms_facility {
			id
			overallRating: overall_rating
			metrics(
				where: {
					measure_id: {
						_in: [
							"OP_18b"
							"OP_22"
							"SEP_1"
							"MORT_30_AMI"
							"MORT_30_COPD"
							"MORT_30_STK"
							"EDV"
						]
					}
				}
			) {
				id
				measureId: measure_id
				score
			}
			hospitalType: hospital_type
			facilities(order_by: [{ name: asc }]) {
				id
				name
			}
		}
		cmsFacilityPos: cms_facility_pos {
			id
			providerSubtype: provider_subtype
		}
		cmsFacilityEnrollment: cms_facility_enrollment {
			id
			providerTypeCode: provider_type_code
		}
		ahaFacility: aha_facility_22 {
			id: ID
			medSchool: MAPP5
			healthSystem: SYSNAME
			svc1 {
				id: ID
				cathLab: ICLABHOS
				adultTraumaLvl: TRAUML90
				hospBeds: HOSPBD
				genBeds: GENBD
				genPedBeds: PEDBD
				msicBeds: MSICBD
				pedicBeds: PEDICBD
				burnBeds: BRNBD
				psychBeds: PSYBD
			}
			svc2 {
				id: ID
				psychOutpatientServices: PSYOPHOS
				pscyhEmergencyServices: PSYEMHOS
				psychPedBeds: PSCBD
				psychGerBeds: PSGBD
			}
			units {
				id: ID
				name: UNAME
				par: parent_aha_facility {
					id: ID
					name: MNAME
				}
			}
			rollups @client
		}
		facilityType @client
		contracts(where: { active: { _eq: true } }, order_by: { created_at: asc }) {
			id
			org {
				id
				ownershipStructure: ownership_structure
			}
		}
	}
`);

export interface FacilityFeaturesProps extends BoxProps {
	facility: FragmentType<typeof FacilityFeatures_FacilityFDoc>;
	onClickShowEmployers: ApplyToJobButtonProps['onClickShowEmployers'];
}

const FacilityFeatures = ({
	facility: rawFacility,
	onClickShowEmployers,
	...props
}: FacilityFeaturesProps) => {
	const currAcc = useCurrentAccount();
	const facility = getFragmentData(FacilityFeatures_FacilityFDoc, rawFacility);

	const facilityFields = useMemo(() => {
		return getFacilityFields({ onClickShowEmployers });
	}, [onClickShowEmployers]);

	return (
		<Box component='section' {...props}>
			{facilityFields.map((featureSet, idx) => (
				<React.Fragment key={featureSet.section}>
					<FeatureSet
						facility={facility}
						{...featureSet}
						showDisclaimer={!idx}
						sx={
							idx
								? {
										mt: {
											xs: 6,
											md: 10,
										},
								  }
								: undefined
						}
					/>
					{!idx && (
						<BannerAd
							slotId={'facility-view'}
							bp={'sm'}
							category={{
								disjunctions: [
									['SPECIALTY_EM', 'SPECIALTY_ALL'],
									currAcc?.clinician?.profession
										? [
												`PROFESSION_${currAcc.clinician.profession}`,
												'PROFESSION_ALL',
										  ]
										: ['PROFESSION_ALL'],
									[`LOCATION_${facility.state}`, 'LOCATION_ALL'],
								],
							}}
							sx={{
								mt: {
									xs: 6,
									md: 10,
								},
							}}
						/>
					)}
				</React.Fragment>
			))}
		</Box>
	);
};

export default FacilityFeatures;

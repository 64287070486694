import React, { useCallback } from 'react';

import {
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	Checkbox,
} from '@mui/material';

import { PROFESSION2PROFVERBOSE } from '@ivy/constants/clinician';

const ProfessionFilter = ({ value, onChange }) => {
	const renderLists = useCallback(() => {
		return Object.entries(PROFESSION2PROFVERBOSE).map(([k, v], idx, self) => (
			<ListItem
				button
				key={`profession-select-${k}`}
				onClick={() =>
					onChange(
						undefined,
						value.includes(k) ? value.filter((el) => el !== k) : [...value, k],
					)
				}
				sx={(theme) => ({
					pl: 0,
					py: 1,
					borderBottom:
						idx !== self.length - 1
							? `1px solid ${theme.palette.divider}`
							: 'none',
				})}
			>
				<ListItemIcon>
					<Checkbox checked={value.includes(k)} />
				</ListItemIcon>
				<ListItemText primary={v as string} />
			</ListItem>
		));
	}, [value, onChange]);

	return <List sx={{ p: 0 }}>{renderLists()}</List>;
};

export default ProfessionFilter;

import React, {
	useEffect,
	useState,
	useRef,
	useCallback,
	useMemo,
} from 'react';

import { useMutation } from '@apollo/client';
import { useApolloClient } from '@apollo/client';
import {
	Box,
	type SxProps,
	ThemeProvider,
	type Theme,
	useMediaQuery,
	Typography,
	Stack,
	Button,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { type FormikHelpers } from 'formik-othebi';
import { type FormikProps, Form, FormikProvider } from 'formik-othebi';
import { useSnackbar } from 'notistack';

import {
	onboardingFormSteps as formSteps,
	onboardingInitialValues as initialFormValues,
	type OnboardingFormValues,
	type FormStepObj,
} from '@ivy/components/forms/OnboardingForm';
import { FormButton, BaseFormPopup } from '@ivy/components/molecules/FormPopup';
import { useAuthPopup } from '@ivy/components/providers/AuthPopupProvider';
import { AccountSignupStatus } from '@ivy/constants/account';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql } from '@ivy/gql/types';
import { useForm } from '@ivy/lib/hooks';
import { getInvitee, clearInvitee } from '@ivy/lib/storage/invitee';
import { combineSx } from '@ivy/lib/styling/sx';
import { bubbleTheme as bt } from '@ivy/theme/bubble';

interface StepFormatValues {
	keys: string[];
	values: FormStepObj[];
}

interface ActionCompProps {
	formik: FormikProps<OnboardingFormValues>;
	onClickLogin?: (event: React.SyntheticEvent) => void;
	steps: StepFormatValues;
	activeStep: number;
	sx?: SxProps<Theme>;
	currAccLoading: boolean;
}

const ActionComp = ({
	formik,
	onClickLogin,
	activeStep,
	steps,
	sx,
	currAccLoading,
}: ActionCompProps) => {
	const stepObj = steps.values[activeStep];

	return (
		<Stack
			direction={{ sm: 'row', xs: 'column-reverse' }}
			sx={combineSx(
				{ alignItems: 'center', width: { sm: 'auto', xs: '100%' } },
				sx,
			)}
		>
			<Typography variant='body2' align='center'>
				Already have an account?
				<Button
					color='primary'
					onClick={onClickLogin}
					sx={{
						fontSize: 'inherit',
						verticalAlign: 'baseline',
					}}
				>
					Log in
				</Button>
			</Typography>
			<FormButton
				variant='contained'
				id={stepObj.googleAnalyticsTag}
				size='large'
				disabled={
					!formik.dirty ||
					!formik.isValid ||
					formik.isSubmitting ||
					currAccLoading
				}
				onClick={formik.submitForm}
			>
				{stepObj.finalStep ? 'Create account' : 'Next'}
			</FormButton>
		</Stack>
	);
};

export const OnboardingPopup_CheckOrgSignupStatusMDoc = gql(/* GraphQL */ `
	mutation OnboardingPopup_CheckOrgSignupStatus(
		$email: String!
		$orgId: String!
		$sendVerification: Boolean!
	) {
		signupStatus: check_org_user_signup_status(
			email: $email
			org_id: $orgId
			send_verification: $sendVerification
		) {
			status
			description
			firstName: first_name
			lastName: last_name
		}
	}
`);

const formatSteps = (steps: {
	[key: string]: FormStepObj;
}): { keys: string[]; values: FormStepObj[] } => {
	const updatedSteps = Object.keys(steps).reduce(
		(result: { [key: string]: FormStepObj }, key) => {
			result[key] = steps[key];
			return result;
		},
		{},
	);

	return {
		keys: Object.keys(updatedSteps),
		values: Object.values(updatedSteps),
	};
};

interface OnboardingPopupProps {
	currAccLoading: boolean;
	open: boolean;
	onClose: () => void;
	onSuccess?: () => void;
}

const OnboardingPopup = ({
	currAccLoading,
	onClose,
	onSuccess,
	open,
}: OnboardingPopupProps) => {
	const { enqueueSnackbar } = useSnackbar();
	const currSteps = formatSteps(formSteps);
	const [currStepKey, setCurrStepKey] = useState(0);
	const currAcc = useCurrentAccount();
	const formRef = useRef<HTMLFormElement>(null);
	const client = useApolloClient();
	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('sm'),
	);

	const { openLoginPopup } = useAuthPopup();

	// Note we always check invitee alongside data in case the user resets the invitee, where then invitee will
	// be null but data will be non-null
	const [invitee, setInvitee] = useState(getInvitee);
	// This error is ignored if there is something wrong and only used to control UI loading state
	// (e.g. invalid UUID happens in some spam checkers in email clients, missing email or orgId)
	const [verify, { data }] = useMutation(
		OnboardingPopup_CheckOrgSignupStatusMDoc,
		{
			variables: {
				email: invitee?.inviteEmail,
				orgId: invitee?.inviteOrgId,
				sendVerification: false,
			},
		},
	);

	useEffect(() => {
		// Wait until we've fetched the newly signed up user before closing
		if (currAcc) {
			onSuccess?.();
		}
	}, [currAcc, onSuccess]);

	useEffect(() => {
		if (!invitee) return;

		const performVerify = async () => {
			try {
				// Pre-populate first name, last name, org, and email of invitee
				await verify();
				// TODO: we used to automatically push them over to the org signup, but now, there's no back button
				//  to go to the clinician signup if need be.
			} catch (e) {
				captureException(e, {
					extra: {
						invitee,
					},
				});
			}
		};
		performVerify();
	}, [invitee, verify]);

	const resetInvitee = useCallback(() => {
		clearInvitee();
		setInvitee(null);
	}, [setInvitee]);

	useEffect(() => {
		if (
			!invitee ||
			!data ||
			data.signupStatus.status === AccountSignupStatus.UNCLAIMED
		) {
			return;
		}
		// Signup status no longer valid, clear out the invitation
		resetInvitee();
	}, [invitee, data, resetInvitee]);

	const defaultFormValues = useMemo(() => {
		const defaultValues = {};
		const signupStatus = data?.signupStatus;

		if (
			invitee &&
			signupStatus &&
			signupStatus.status === AccountSignupStatus.UNCLAIMED
		) {
			Object.assign(defaultValues, {
				firstName: signupStatus.firstName || '',
				lastName: signupStatus.lastName || '',
				email: invitee.inviteEmail,
				orgId: invitee.inviteOrgId,
			});
		}

		return Object.assign({}, initialFormValues, defaultValues);
	}, [data, invitee]);

	const stepObj = currSteps.values[currStepKey];
	const schema = stepObj.validation;

	const handleNext = (values: OnboardingFormValues) => {
		if (stepObj.nextStep) {
			setCurrStepKey(currSteps.keys.indexOf(stepObj.nextStep(values)));
		}
	};

	const handleSuccess = (
		values: OnboardingFormValues,
		actions: FormikHelpers<OnboardingFormValues>,
		successText = '',
	) => {
		if (successText) enqueueSnackbar(successText, { variant: 'success' });
		handleNext(values);
		actions.setTouched({});
		actions.setSubmitting(false);
		actions.resetForm({ values });
	};

	const handleError = (
		actions: FormikHelpers<OnboardingFormValues>,
		e: unknown,
		extra?: { [key: string]: object },
		errorText = '',
	) => {
		if (errorText) enqueueSnackbar(errorText, { variant: 'error' });
		actions.setSubmitting(false);
		if (e instanceof Error) {
			console.error(e);
			if (!errorText)
				enqueueSnackbar(e.message, {
					variant: 'error',
				});
			actions.setStatus(e.message);
			captureException(e, {
				extra,
			});
		}
	};

	const formik = useForm<OnboardingFormValues>({
		initialValues: defaultFormValues,
		onSubmit: async (values, actions) => {
			if (formRef?.current) formRef.current?.scrollIntoView();
			if (stepObj.submit) {
				await stepObj.submit(
					values,
					actions,
					client,
					(txt) => handleSuccess(values, actions, txt),
					(e, extra) => handleError(actions, e, extra),
				);
			} else {
				handleSuccess(values, actions);
			}
		},
		enableReinitialize: true, // Reinitialize since the invitee and or signupStatus objects are still being resolved from the backend.
		validationSchema: schema,
	});

	// Don't show to authenticated users
	if (currAcc) return null;

	return (
		<ThemeProvider theme={bt}>
			<FormikProvider value={formik}>
				<BaseFormPopup
					open={open}
					onClose={onClose}
					actions={
						isMobile ? (
							<ActionComp
								formik={formik}
								activeStep={currStepKey}
								steps={currSteps}
								// Original callbacks will be passed to openLoginPopup
								onClickLogin={() => openLoginPopup()}
								currAccLoading={currAccLoading}
							/>
						) : undefined
					}
					fullWidth
					contentSx={{
						display: 'flex',
						pt: { sm: 10, xs: 4 },
						pb: { sm: 15, xs: 0 },
					}}
				>
					<Box component={Form} sx={{ flex: 1 }} ref={formRef}>
						<Stack
							direction='column'
							alignItems='flex-end'
							sx={{
								maxWidth: '974px',
								maxHeight: '900px',
								margin: 'auto',
								height: '100%',
								width: '100%',
							}}
						>
							<Box sx={{ width: '100%', mb: 'auto' }}>
								<stepObj.component
									formik={formik}
									currAccLoading={currAccLoading}
									onClickReset={resetInvitee}
								/>
							</Box>
							<ActionComp
								formik={formik}
								activeStep={currStepKey}
								steps={currSteps}
								// Original callbacks will be passed to openLoginPopup
								onClickLogin={() => openLoginPopup()}
								currAccLoading={currAccLoading}
								sx={{ display: !isMobile ? 'flex' : 'none', mt: 5 }}
							/>
						</Stack>
					</Box>
				</BaseFormPopup>
			</FormikProvider>
		</ThemeProvider>
	);
};

export default OnboardingPopup;

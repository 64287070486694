import React from 'react';

import { type ApolloError } from '@apollo/client';
import isFunction from 'lodash/isFunction';

import ErrorScreen from '@ivy/components/molecules/ErrorScreen';
import LoadingScreen, {
	type LoadingScreenProps,
} from '@ivy/components/molecules/LoadingScreen';
import NotFoundScreen from '@ivy/components/organisms/NotFoundScreen';

export interface DataLoaderProps<T> {
	data?: T;
	loading?: boolean;
	error?: ApolloError;
	keys?: (keyof T)[];
	children?: React.ReactNode | (() => React.ReactNode);
	variant?: LoadingScreenProps['variant'];
	showErrorMessage?: boolean;
	fullscreen?: boolean;
	skipCheckData?: boolean;
	transparent?: boolean;
}

const DataLoader = <T,>({
	data,
	loading,
	error,
	keys,
	children,
	variant,
	showErrorMessage = false,
	fullscreen,
	skipCheckData = false,
	transparent = false,
}: DataLoaderProps<T>) => {
	if (error) {
		return (
			<ErrorScreen
				errorMessage={showErrorMessage ? error.message : undefined}
				fullscreen={fullscreen}
			/>
		);
	}

	if (loading) {
		return (
			<LoadingScreen
				fullscreen={fullscreen}
				variant={variant}
				transparent={transparent}
			/>
		);
	}

	if (!skipCheckData) {
		if (!data) {
			return (
				<LoadingScreen
					fullscreen={fullscreen}
					variant={variant}
					transparent={transparent}
				/>
			);
		} else if (keys && keys.length && keys.some((key) => data[key] == null)) {
			return <NotFoundScreen fullscreen={fullscreen} />;
		}
	}

	return <>{children && isFunction(children) ? children() : children}</>;
};

export default DataLoader;

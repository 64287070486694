import React from 'react';

import {
	Box,
	SvgIcon,
	type SxProps,
	type Theme,
	Typography,
} from '@mui/material';

import { combineSx } from '@ivy/lib/styling/sx';

export interface ValueProp2Props {
	sx?: SxProps<Theme>;
}

const ValueProp2 = ({ sx }: ValueProp2Props) => {
	return (
		<Box
			component='section'
			sx={combineSx(
				{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					bgcolor: '#CDEFDC',
				},
				sx,
			)}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: {
						xs: 'column',
						md: 'row',
					},
					maxWidth: '1400px',
					height: {
						md: '100%',
					},
					alignItems: 'center',
					px: 3,
				}}
			>
				<Box
					sx={{
						flexBasis: {
							md: '40%',
						},
						mr: {
							md: 10,
						},
					}}
				>
					<Typography
						variant='h2'
						sx={{
							lineHeight: 1.3,
							mb: 5,
							textAlign: {
								xs: 'center',
								md: 'initial',
							},
						}}
					>
						Fill your candidate pipeline
					</Typography>
					<Typography
						variant='body1'
						sx={{
							color: 'text.icon',
							textAlign: {
								xs: 'center',
								md: 'initial',
							},
						}}
					>
						Post a job to let clinicians know which of your sites are hiring, or
						save their CV for future openings at facilities that are already
						fully staffed.
					</Typography>
					<SvgIcon
						width='145'
						height='88'
						viewBox='0 0 145 88'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
						sx={{
							width: '145px',
							height: '88px',
							mt: 5,
							display: {
								xs: 'none',
								md: 'initial',
							},
						}}
					>
						<path
							d='M1.20642 36.971C-0.387242 61.3673 125.435 -11.8996 124.113 4.08904C122.792 20.0776 41.1995 64.3208 38.3026 79.4565C35.4057 94.5923 143.752 28.774 143.336 41.5856C142.92 54.3971 102.403 67.9111 99.1581 81.4324C95.9129 94.9536 146.052 81.3516 142.426 69.611'
							stroke='#1EC86A'
							strokeWidth='1.60231'
							fill='none'
						/>
					</SvgIcon>
				</Box>
				<Box
					sx={{
						flexBasis: {
							md: '60%',
						},
						width: {
							xs: '100%',
							md: 'initial',
						},
						maxWidth: {
							xs: '500px',
							md: 'none',
						},
						position: 'relative',
						aspectRatio: '1.1357 / 1',
						mt: {
							xs: 5,
							md: 0,
						},
					}}
				>
					<SvgIcon
						width='808'
						height='751'
						viewBox='0 0 808 751'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
						sx={{
							position: 'absolute',
							zIndex: 1,
							width: '94.74%',
							height: '100%',
							left: 0,
							top: 0,
						}}
					>
						<g opacity='0.2'>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M597.48 233.066C759.642 310.896 858.552 493.477 780.721 655.639C712.712 797.34 521.191 755.266 379.49 687.257C263.825 631.743 177.938 508.64 233.451 392.975C300.638 252.99 457.495 165.88 597.48 233.066Z'
								fill='#2682C5'
							/>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M327.279 187.147C353.384 113.624 399.282 37.6754 467.385 10.2401C537.038 -17.8198 603.296 18.5713 663.034 51.8877C723.895 85.8299 792.195 122.754 804.185 198.075C816.034 272.516 765.684 347.655 720.806 414.827C680.25 475.53 630.384 528.732 566.809 553.376C501.287 578.774 424.626 590.486 375.643 548.622C329.632 509.298 351.365 427.366 342.584 361.74C334.588 301.979 305.862 247.467 327.279 187.147Z'
								fill='#1EC86A'
							/>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M203.542 654.211C128.113 634.274 48.6298 594.811 15.6587 529.209C-18.0631 462.114 12.7263 393.075 40.9903 330.787C69.7853 267.329 100.937 196.21 175.009 178.035C248.216 160.072 327.26 204.039 397.911 243.21C461.76 278.609 518.902 323.906 548.716 385.227C579.444 448.425 597.453 523.855 559.781 576.132C524.396 625.236 440.947 610.351 376.272 624.526C317.377 637.435 265.426 670.568 203.542 654.211Z'
								fill='#1B8374'
							/>
						</g>
					</SvgIcon>

					<Box
						component='img'
						src='https://assets.ivyclinicians.io/gifs/landing-page%3Aemployer%3Acandidate%3Acropped.gif'
						sx={{
							position: 'absolute',
							zIndex: 2,
							left: '3.89%',
							top: '26.37%',
							height: '50.27%',
							borderRadius: '32px',
							boxShadow: '0px 16px 40px rgba(142, 149, 155, 0.3)',
						}}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default ValueProp2;

import React from 'react';

import {
	AttachMoney,
	NightsStay,
	CalendarToday,
	Elderly,
	DirectionsWalk,
	School,
} from '@mui/icons-material';
import {
	Box,
	Alert,
	type SxProps,
	type Theme,
	Stack,
	Divider,
	Typography,
	Grid,
	Card,
	Chip,
} from '@mui/material';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { SignIcon } from '@ivy/components/icons';
import { gql, getFragmentData, type FragmentType } from '@ivy/gql/types';
import { combineSx } from '@ivy/lib/styling/sx';
import { buildInternalLink } from '@ivy/lib/util/route';

import { dataFormatter, CHIPVAULT } from './dataFormatter';

export const ReportDetail_SalaryReportInfoFDoc = gql(/* GraphQL */ `
	fragment ReportDetail_SalaryReportInfo on salary_report_fmt {
		id
		baseAmt: base_amt_fmt
		basePeriod: base_period
		profession
		worker
		nightDiff: night_diff
		nightDiffAmt: night_diff_amt_fmt
		weekendDiff: weekend_diff
		weekendDiffAmt: weekend_diff_amt_fmt
		experience
		hrsShift: hrs_per_shift
		shftsMonth: shifts_per_month
		createdAt: created_at

		visaSponsorship: visa_sponsorship
		nocturnal
		partner
		locums
		weekendsOnly: weekends_only
		partnershipTrack: partnership_track
		admin
		traveler

		cme
		cmeAmt: cme_amt_fmt
		bonus
		bonusAmt: bonus_amt
		retirement
		retirementAmt: retirement_amt
		studentLoan: student_loan
		studentLoanAmt: student_loan_amt_fmt

		facility {
			id
			name
			slug
			state
			city
		}

		organization {
			id
			name
			slug
		}
		orgOther: org_other
	}
`);

const generateChips = (obj) => {
	const chips = Object.entries(obj)
		.map(([k, v]) => {
			if (!v) return null;
			return (
				<Grid item key={`chip-identity-${k}`}>
					<Chip
						label={CHIPVAULT[k].label}
						sx={{
							bgcolor: 'primary.main',
							color: 'primary.contrastText',
							fontWeight: 'bold',
						}}
					/>
				</Grid>
			);
		})
		.filter((el) => !!el);
	if (!chips.length) {
		return (
			<Typography variant='caption' ml={2}>
				No details to display
			</Typography>
		);
	}
	return chips;
};

interface ReportDetailProps {
	report: FragmentType<typeof ReportDetail_SalaryReportInfoFDoc>;
	sx?: SxProps<Theme>;
}

const ReportDetail = ({ report: rawReport, sx }: ReportDetailProps) => {
	const report = getFragmentData(ReportDetail_SalaryReportInfoFDoc, rawReport);
	const formattedReport = dataFormatter(report);

	return (
		<>
			<Divider sx={{ display: { gridBreak: 'block', xs: 'none' } }} />
			<Stack
				sx={combineSx(
					{
						px: {
							gridBreak: 8,
							xs: 0,
						},
						pt: {
							gridBreak: 6,
							xs: 0,
						},
						pb: {
							gridBreak: 3,
							xs: 0,
						},
					},
					sx,
				)}
			>
				<Stack
					spacing={5}
					direction={{ gridBreak: 'column', xs: 'column-reverse' }}
				>
					{/* <Box
            display='flex'
            justifyContent={{ gridBreak: 'flex-end', xs: 'flex-start' }}
            sx={{ mt: { gridBreak: 3, xs: 0 } }}
          >
            <Typography
              variant='caption'
              display='inline'
              sx={{ mr: 3 }}
              color='primary.main'
            >
              <DoNotDisturbAlt
                sx={{
                  position: 'relative',
                  color: 'inherit',
                  top: '2px',
                  mr: 1,
                  fontSize: '1.1em',
                }}
              />
              Report
            </Typography>
            <Typography variant='caption' display='inline' color='primary.main'>
              <IosShare
                sx={{
                  position: 'relative',
                  color: 'inherit',
                  top: '2px',
                  mr: 1,
                  fontSize: '1.1em',
                }}
              />
              Share
            </Typography>
          </Box> */}
					<Stack
						direction={{ xs: 'column', gridBreak: 'row' }}
						spacing={{ xs: 5, gridBreak: 8 }}
					>
						<Stack
							flex={2}
							direction={{ gridBreak: 'column', xs: 'column-reverse' }}
							sx={{ maxWidth: { gridBreak: '488px', xs: 'none' } }}
							spacing={{ xs: 5, gridBreak: 8 }}
						>
							<Card
								sx={{
									p: { gridBreak: 5, sm: 4, xs: 3 },
									flex: 1,
									borderRadius: '16px',
									boxShadow: '0px 16px 40px rgba(142, 149, 155, 0.3)',
								}}
							>
								<Box>
									<Typography component='p' variant='h6' sx={{ mb: 1.5 }}>
										{formattedReport.profession}
									</Typography>
									<Typography variant='body1'>
										<RouteLink
											to={buildInternalLink(RouteLink.routes.FACILITY_SHOW, {
												facilityId: [
													formattedReport.facilityId,
													formattedReport.facilitySlug,
												],
											})}
											underline='hover'
										>
											{formattedReport.facility}
										</RouteLink>
									</Typography>
									<Typography variant='body1' sx={{ mb: 0.5 }}>
										{formattedReport.orgId ? (
											<RouteLink
												to={buildInternalLink(RouteLink.routes.ORG_SHOW, {
													orgId: [
														formattedReport.orgId,
														formattedReport.orgSlug,
													],
												})}
												underline='hover'
											>
												{formattedReport.org}
											</RouteLink>
										) : (
											formattedReport.org
										)}
									</Typography>
								</Box>
								<Divider sx={{ my: 3 }} />
								<Stack spacing={2}>
									<Box display='flex' justifyContent='space-between'>
										<Typography
											variant='body2'
											fontWeight={600}
											display='inline'
										>
											Contract type
										</Typography>
										<Typography variant='body2' display='inline'>
											{formattedReport.worker}
										</Typography>
									</Box>
									<Box display='flex' justifyContent='space-between'>
										<Typography
											variant='body2'
											fontWeight={600}
											display='inline'
										>
											EM experience
										</Typography>
										<Typography variant='body2' display='inline'>
											{formattedReport.experience}
										</Typography>
									</Box>
									<Box display='flex' justifyContent='space-between'>
										<Typography
											variant='body2'
											fontWeight={600}
											display='inline'
										>
											Shifts per month
										</Typography>
										<Typography variant='body2' display='inline'>
											{formattedReport.shftsMonth}
										</Typography>
									</Box>
									<Box display='flex' justifyContent='space-between'>
										<Typography
											variant='body2'
											fontWeight={600}
											display='inline'
										>
											Average hours per shift
										</Typography>
										<Typography variant='body2' display='inline'>
											{formattedReport.hrsShift}
										</Typography>
									</Box>
								</Stack>
							</Card>
							<Alert severity='info' sx={{ width: 'fit-content', mt: 1 }}>
								Salary data is self-reported by physicians, physician
								assistants, and nurse practitioners.
							</Alert>
						</Stack>
						<Box flex={3} sx={{ maxWidth: { gridBreak: '615px', xs: 'none' } }}>
							<Card
								sx={{
									p: { gridBreak: 5, sm: 4, xs: 3 },
									borderRadius: '16px',
									boxShadow: '0px 16px 40px rgba(142, 149, 155, 0.3)',
								}}
							>
								<Stack spacing={4}>
									<Box display='flex'>
										<AttachMoney
											sx={{
												position: 'relative',
												top: '-3px',
												mr: 1,
												color: '#666',
											}}
										/>
										<Stack
											direction={{ gridBreak: 'row', xs: 'column' }}
											justifyContent='space-between'
											flex={1}
										>
											<Typography
												variant='body1'
												display='inline'
												sx={{
													fontWeight: 'bold',
													minWidth: { gridBreak: '250px', xs: 'none' },
												}}
											>
												Base salary
											</Typography>
											<Typography
												display='inline'
												sx={{ fontSize: 'body1.fontSize' }}
											>
												{formattedReport.baseAmt}
											</Typography>
										</Stack>
									</Box>
									<Box display='flex'>
										<NightsStay
											sx={{
												position: 'relative',
												top: '-3px',
												mr: 1,
												color: '#666',
											}}
										/>
										<Stack
											direction={{ gridBreak: 'row', xs: 'column' }}
											justifyContent='space-between'
											flex={1}
										>
											<Typography
												variant='body1'
												display='inline'
												sx={{
													fontWeight: 'bold',
													minWidth: { gridBreak: '250px', xs: 'none' },
												}}
											>
												Night differential pay
											</Typography>
											<Typography
												display='inline'
												sx={{ fontSize: 'body1.fontSize' }}
											>
												{formattedReport.nightDiffAmt}
											</Typography>
										</Stack>
									</Box>
									<Box display='flex'>
										<CalendarToday
											sx={{
												position: 'relative',
												top: '-3px',
												mr: 1,
												color: '#666',
											}}
										/>
										<Stack
											direction={{ gridBreak: 'row', xs: 'column' }}
											justifyContent='space-between'
											flex={1}
										>
											<Typography
												variant='body1'
												display='inline'
												sx={{
													fontWeight: 'bold',
													minWidth: { gridBreak: '250px', xs: 'none' },
												}}
											>
												Weekend differential pay
											</Typography>
											<Typography
												display='inline'
												sx={{ fontSize: 'body1.fontSize' }}
											>
												{formattedReport.weekendDiffAmt}
											</Typography>
										</Stack>
									</Box>
									<Box display='flex'>
										<Elderly
											sx={{
												position: 'relative',
												top: '-3px',
												mr: 1,
												color: '#666',
											}}
										/>
										<Stack
											direction={{ gridBreak: 'row', xs: 'column' }}
											justifyContent='space-between'
											flex={1}
										>
											<Typography
												variant='body1'
												display='inline'
												sx={{
													fontWeight: 'bold',
													minWidth: { gridBreak: '250px', xs: 'none' },
												}}
											>
												Retirement contribution
											</Typography>
											<Typography
												display='inline'
												sx={{ fontSize: 'body1.fontSize' }}
											>
												{formattedReport.retirementAmt}
											</Typography>
										</Stack>
									</Box>
									<Box display='flex'>
										<DirectionsWalk
											sx={{
												position: 'relative',
												top: '-3px',
												mr: 1,
												color: '#666',
											}}
										/>
										<Stack
											direction={{ gridBreak: 'row', xs: 'column' }}
											justifyContent='space-between'
											flex={1}
										>
											<Typography
												variant='body1'
												display='inline'
												sx={{
													fontWeight: 'bold',
													minWidth: { gridBreak: '250px', xs: 'none' },
												}}
											>
												CME funds
											</Typography>
											<Typography
												display='inline'
												sx={{ fontSize: 'body1.fontSize' }}
											>
												{formattedReport.cmeAmt}
											</Typography>
										</Stack>
									</Box>
									<Box display='flex'>
										<SignIcon
											sx={{ position: 'relative', top: '-3px', mr: 1 }}
										/>
										<Stack
											direction={{ gridBreak: 'row', xs: 'column' }}
											justifyContent='space-between'
											flex={1}
										>
											<Typography
												variant='body1'
												display='inline'
												sx={{
													fontWeight: 'bold',
													minWidth: { gridBreak: '250px', xs: 'none' },
												}}
											>
												Bonus / profit share
											</Typography>
											<Typography
												display='inline'
												sx={{ fontSize: 'body1.fontSize' }}
											>
												{formattedReport.bonusAmt}
											</Typography>
										</Stack>
									</Box>
									<Box display='flex'>
										<School
											sx={{
												position: 'relative',
												top: '-3px',
												mr: 1,
												color: '#666',
											}}
										/>
										<Stack
											direction={{ gridBreak: 'row', xs: 'column' }}
											justifyContent='space-between'
											flex={1}
										>
											<Typography
												variant='body1'
												display='inline'
												sx={{
													fontWeight: 'bold',
													minWidth: { gridBreak: '250px', xs: 'none' },
												}}
											>
												{/* TODO for some reason StudentMaleCapIcon is rendering pencil icon */}
												Student Loan reimbursement
											</Typography>
											<Typography
												display='inline'
												sx={{ fontSize: 'body1.fontSize' }}
											>
												{formattedReport.studentLoanAmt}
											</Typography>
										</Stack>
									</Box>
								</Stack>
							</Card>
						</Box>
						<Stack
							justifyContent={{ gridBreak: 'space-between' }}
							spacing={{ gridBreak: 0, xs: 5 }}
							flex={1}
						>
							<Box>
								<Typography sx={{ mb: 4, fontWeight: 'bold' }}>
									Additional Details
								</Typography>
								<Grid container spacing={2}>
									{generateChips({
										admin: formattedReport.admin,
										locums: formattedReport.locums,
										partner: formattedReport.partner,
										traveler: formattedReport.traveler,
										nocturnal: formattedReport.nocturnal,
										weekendsOnly: formattedReport.weekendsOnly,
										visaSponsorship: formattedReport.visaSponsorship,
										partnershipTrack: formattedReport.partnershipTrack,
									})}
								</Grid>
							</Box>
							{/* <Typography
              variant='caption'
              textAlign={{ gridBreak: 'right', xs: 'left' }}
            >
              Was this helpful?
              <ThumbUp
                sx={{
                  position: 'relative',
                  color: 'inherit',
                  top: '2px',
                  ml: 2,
                  fontSize: '1.1em',
                }}
              />
              <ThumbDown
                sx={{
                  position: 'relative',
                  color: 'inherit',
                  top: '2px',
                  ml: 2,
                  fontSize: '1.1em',
                }}
              />
            </Typography> */}
						</Stack>
					</Stack>
				</Stack>
				<Typography
					variant='caption'
					display='inline'
					textAlign={{ gridBreak: 'center', xs: 'left' }}
					sx={{ mt: 6 }}
				>
					Submitted on {formattedReport.createdAt}
				</Typography>
			</Stack>
			<Divider sx={{ display: { gridBreak: 'block', xs: 'none' } }} />
		</>
	);
};

export default ReportDetail;

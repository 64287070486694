import React from 'react';

import { Person, Search } from '@mui/icons-material';
import { type Theme, useMediaQuery, ThemeProvider } from '@mui/material';
import {
	type SxProps,
	Stack,
	Typography,
	Card,
	CardContent,
	Box,
	Divider,
} from '@mui/material';

import { WishListIcon } from '@ivy/components/icons';
import { FormButton, BaseFormPopup } from '@ivy/components/molecules/FormPopup';
import { useAuthPopup } from '@ivy/components/providers/AuthPopupProvider';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { combineSx } from '@ivy/lib/styling/sx';
import { bubbleTheme as bt } from '@ivy/theme/bubble';

const TESTIMONIAL_IMAGE =
	'https://assets.ivyclinicians.io/images/omar-nava.jpeg';

interface ActionCompProps {
	onClick: (event?: React.SyntheticEvent) => void;
	onClose?: (event?: React.SyntheticEvent<Element, Event>) => void;
	sx?: SxProps<Theme>;
}

const ActionComp = ({ onClick, onClose, sx }: ActionCompProps) => {
	return (
		<Stack
			direction={{ sm: 'row', xs: 'column' }}
			sx={combineSx(
				{ alignItems: 'center', width: { sm: 'auto', xs: '100%' } },
				sx,
			)}
		>
			<FormButton
				variant='outlined'
				size='large'
				onClick={onClose}
				sx={{
					mb: { sm: 0, xs: 1 },
					ml: 0,
				}}
			>
				Do this later
			</FormButton>
			<Stack
				display='flex'
				sx={{
					ml: { sm: 3, xs: 0 },
					alignItems: 'center',
					position: 'relative',
				}}
				width={{ sm: 'auto', xs: '100%' }}
			>
				<FormButton
					variant='contained'
					size='large'
					onClick={onClick}
					sx={{
						ml: 0,
						mb: { sm: 0, xs: 3 },
					}}
				>
					Create your profile
				</FormButton>
				<Typography
					variant='caption'
					sx={{ position: 'absolute', bottom: { sm: '-24px', xs: 0 } }}
				>
					🕛 Takes around 3-4 minutes
				</Typography>
			</Stack>
		</Stack>
	);
};

interface CompleteSignupPopupProps {
	open: boolean;
	onClose?: (event?: React.SyntheticEvent<Element, Event>) => void;
}

const CompleteSignupPopup = ({ open, onClose }: CompleteSignupPopupProps) => {
	const { openProfilePopup } = useAuthPopup();
	const currAcc = useCurrentAccount();
	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('sm'),
	);

	const handleClick = () => {
		openProfilePopup();
	};

	return (
		<ThemeProvider theme={bt}>
			<BaseFormPopup
				open={open}
				onClose={onClose}
				actions={
					isMobile ? (
						<ActionComp onClick={handleClick} onClose={onClose} />
					) : undefined
				}
				fullWidth
				contentSx={{
					display: 'flex',
					form: { flex: 1 },
					pt: { sm: 10, xs: 4 },
					pb: { sm: 15, xs: 0 },
					px: { md: 10, xs: 3 },
				}}
			>
				<Stack
					direction='row'
					sx={{
						margin: 'auto',
						height: '100%',
						width: '100%',
					}}
				>
					<Stack direction={{ sm: 'row', xs: 'column' }} sx={{ mx: 'auto' }}>
						<Stack
							direction='column'
							justifyContent='space-between'
							sx={{ maxWidth: '730px', minWidth: { md: '550px', xs: 0 } }}
						>
							<Stack>
								<Typography
									variant='h3'
									component='h1'
									textAlign={{ sm: 'start', xs: 'center' }}
									sx={{ mb: { sm: 15, xs: 10 } }}
								>
									✅ You’re all signed up,{' '}
									{currAcc?.clinician?.account.pi?.firstName}! Are you ready for
									the next steps?
								</Typography>
								<Stack spacing={4.5}>
									<Stack direction='row'>
										<Person
											sx={{
												color: 'text.icon',
												verticalAlign: 'middle',
												mr: 2,
											}}
										/>
										<Typography variant='body1' color='text.icon'>
											Complete your profile by importing your credentials &
											uploading your CV.
										</Typography>
									</Stack>
									<Divider />
									<Stack direction='row'>
										<Search
											sx={{
												color: 'text.icon',
												verticalAlign: 'middle',
												mr: 2,
											}}
										/>

										<Typography variant='body1' color='text.icon'>
											Search for facilities by location and filter by your
											preferences.
										</Typography>
									</Stack>
									<Divider />
									<Stack direction='row'>
										<WishListIcon
											sx={{
												color: 'text.icon',
												verticalAlign: 'middle',
												mr: 2,
											}}
										/>
										<Typography variant='body1' color='text.icon'>
											Apply to get in touch with the employer about job
											opportunities.
										</Typography>
									</Stack>
									<Divider />
								</Stack>
							</Stack>
							<ActionComp
								onClick={handleClick}
								onClose={onClose}
								sx={{ display: !isMobile ? 'flex' : 'none' }}
							/>
						</Stack>
						<Card
							sx={{
								bgcolor: 'white',
								boxShadow: '0px 16px 40px rgba(142, 149, 155, 0.3)',
								borderRadius: '15.732px',
								maxWidth: '590px',
								display: {
									lg: 'block',
									xs: 'none',
								},
								mb: 'auto',
								ml: 17,
							}}
						>
							<CardContent
								sx={{
									display: 'flex',
									p: 5,
									justifyContent: 'center',
									flexDirection: 'column',
									'&:last-child': {
										pb: 5,
									},
								}}
							>
								<Box
									component='img'
									src={TESTIMONIAL_IMAGE}
									sx={{
										mb: 5,
										width: '100%',
										height: '100%',
										objectFit: 'cover',
										borderRadius: '18.521px',
									}}
								/>
								<Typography
									variant='body1'
									fontStyle='italic'
									color='text.icon'
									sx={{ mb: 3 }}
								>
									"One quick search, a few filters, and I can see all the viable
									job options available in areas I like. Contacting the right
									person at each practice to get more info is simple. Once you
									try Ivy, you’ll never use any other job board again."
								</Typography>
								<Typography
									variant='h6'
									component='p'
									color='text.icon'
									sx={{ opacity: 0.5 }}
								>
									- Omar Nava, MPAS, CAQ, EMPA-C
								</Typography>
							</CardContent>
						</Card>
					</Stack>
				</Stack>
			</BaseFormPopup>
		</ThemeProvider>
	);
};

export default CompleteSignupPopup;
export { type CompleteSignupPopupProps };

import React, { useMemo, useEffect, useState } from 'react';

import {
	type DocumentNode,
	useLazyQuery,
	type TypedDocumentNode,
	type OperationVariables,
} from '@apollo/client';
import { useParams } from 'react-router-dom';

import DataLoader from '@ivy/components/molecules/DataLoader';
import { Profession, SLUG2PROFESSION } from '@ivy/constants/clinician';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql } from '@ivy/gql/types';
import {
	resolveLineStringToViewport,
	resolvePointToCenter,
} from '@ivy/lib/formatting/gMapsLink';

import BaseMap, {
	type BaseMapProps,
	type BaseMapItemObject,
	type NearbyBaseMapItemObject,
	type MapLocation,
} from './BaseMap';
import { type FilterDataStructure } from './filters/useEntityFilters';
import MapProvider from './MapProvider';
import useMapContext from './MapProvider/useMapContext';

type MasterMapProps<
	T extends BaseMapItemObject,
	V extends NearbyBaseMapItemObject,
> = Omit<BaseMapProps<T, V>, 'data' | 'nearbyData'>;

const MasterMap = <
	T extends BaseMapItemObject,
	V extends NearbyBaseMapItemObject,
>({
	...props
}: MasterMapProps<T, V>) => {
	const { dataResolver, dataNearbyResolver } = useMapContext<
		unknown,
		unknown,
		T,
		V
	>();

	const rawData = useMemo(() => {
		return dataResolver();
	}, [dataResolver]);

	const nearbyRawData = useMemo(() => {
		return dataNearbyResolver?.();
	}, [dataNearbyResolver]);

	return <BaseMap data={rawData} nearbyData={nearbyRawData} {...props} />;
};

const Map_GmapLinkQDoc = gql(/* GraphQL */ `
	query Map_GmapLink($slug: String!) {
		gmapsLink: gmaps_link(where: { slug: { _eq: $slug } }) {
			id
			gmapsPlaceId: gmaps_place_id
			center
			label
			slug
			viewport
		}
	}
`);

export interface MapWrapperProps<
	TData,
	TPreviewData,
	TMapItemObject extends BaseMapItemObject = BaseMapItemObject,
	TNearbyItemObject extends NearbyBaseMapItemObject = NearbyBaseMapItemObject,
	TVariables extends OperationVariables = OperationVariables,
	TPreviewVariables extends OperationVariables = OperationVariables,
> extends Omit<
		MasterMapProps<TMapItemObject, TNearbyItemObject>,
		'slug' | 'profession'
	> {
	queryDoc: DocumentNode | TypedDocumentNode<TData, TVariables>;
	queryPreviewDoc:
		| DocumentNode
		| TypedDocumentNode<TPreviewData, TPreviewVariables>;
	filters: FilterDataStructure[];
	resolver: (data?: TData) => TMapItemObject[] | undefined;
	nearbyResolver?: (data?: TData) => TNearbyItemObject[] | undefined;
	previewResolver: (data?: TPreviewData) => TMapItemObject | undefined;
}

const MapWrapper = <
	TData,
	TPreviewData,
	TMapItemObject extends BaseMapItemObject = BaseMapItemObject,
	TNearbyItemObject extends NearbyBaseMapItemObject = NearbyBaseMapItemObject,
>({
	baseRoute,
	defaultLocation,
	queryDoc,
	queryPreviewDoc,
	resolver,
	nearbyResolver,
	previewResolver,
	...props
}: MapWrapperProps<TData, TPreviewData, TMapItemObject, TNearbyItemObject>) => {
	const [init, setInit] = useState(false);
	const currAcc = useCurrentAccount();
	const { slug: rawSlug, profession: rawProfession } = useParams();
	const slug = rawSlug!;
	const profession =
		SLUG2PROFESSION[rawProfession!] ||
		currAcc?.clinician?.profession ||
		Profession.PHYSICIAN;

	// Link data
	const [getData, { data, error }] = useLazyQuery(Map_GmapLinkQDoc);

	useEffect(() => {
		// We only need to fetch the link data if the FIRST load of the page has a slug other than 'search'
		// We do not need to fetch link data on subsequent searches for places
		if (init) {
			return;
		}
		const fetchLink = async () => {
			if (slug !== 'search') {
				await getData({
					variables: {
						slug,
					},
				});
			}
			setInit(true);
		};
		fetchLink();
	}, [init, setInit, slug, getData]);

	const foundLink = data?.gmapsLink[0];
	const newDefaultLocation: MapLocation | undefined = foundLink
		? {
				id: `place-${foundLink.gmapsPlaceId}`,
				type: 'place',
				label: foundLink.label,
				center: resolvePointToCenter(foundLink.center),
				viewport: resolveLineStringToViewport(foundLink.viewport),
				placeId: foundLink.gmapsPlaceId,
				slug: foundLink.slug,
		  }
		: defaultLocation;

	// Don't check keys in data loader because we will still render if no results found
	return (
		<DataLoader
			// We only need to show loading if we're on the first load and we're expecting a gmaps link
			loading={!init}
			error={error}
			variant='logo'
			fullscreen
			skipCheckData
		>
			{() => (
				<MapProvider
					queryDoc={queryDoc}
					queryPreviewDoc={queryPreviewDoc}
					resolver={resolver}
					nearbyResolver={nearbyResolver}
					previewResolver={previewResolver}
				>
					<MasterMap<TMapItemObject, TNearbyItemObject>
						slug={slug}
						profession={profession}
						baseRoute={baseRoute}
						defaultLocation={newDefaultLocation}
						{...props}
					/>
				</MapProvider>
			)}
		</DataLoader>
	);
};

export default MapWrapper;

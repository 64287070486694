// TODO: make immediate needs baadge smaler text
import React, { useState, useMemo } from 'react';

import {
	type QueryHookOptions,
	useQuery,
	type DocumentNode,
	type TypedDocumentNode,
	type OperationVariables,
} from '@apollo/client';

import { useNotifyError } from '@ivy/lib/hooks';

import {
	type BaseMapItemObject,
	type NearbyBaseMapItemObject,
} from '../BaseMap';

import MapContext, { type MapContextValue } from './MapContext';

export interface MapProviderProps<
	TData,
	TPreviewData,
	TMapItemObject extends BaseMapItemObject = BaseMapItemObject,
	TNearbyItemObject extends NearbyBaseMapItemObject = NearbyBaseMapItemObject,
	TVariables extends OperationVariables = OperationVariables,
	TPreviewVariables extends OperationVariables = OperationVariables,
> {
	queryDoc: DocumentNode | TypedDocumentNode<TData, TVariables>;
	queryPreviewDoc:
		| DocumentNode
		| TypedDocumentNode<TPreviewData, TPreviewVariables>;
	resolver: (data?: TData) => TMapItemObject[] | undefined;
	nearbyResolver?: (data?: TData) => TNearbyItemObject[] | undefined;
	previewResolver: (data?: TPreviewData) => TMapItemObject | undefined;
	children?: React.ReactNode;
}

const MapProvider = <
	TData,
	TPreviewData,
	TMapItemObject extends BaseMapItemObject = BaseMapItemObject,
	TNearbyItemObject extends NearbyBaseMapItemObject = NearbyBaseMapItemObject,
	TVariables extends OperationVariables = OperationVariables,
	TPreviewVariables extends OperationVariables = OperationVariables,
>({
	queryDoc,
	queryPreviewDoc,
	resolver,
	nearbyResolver,
	previewResolver,
	children,
}: MapProviderProps<
	TData,
	TPreviewData,
	TMapItemObject,
	TNearbyItemObject,
	TVariables,
	TPreviewVariables
>) => {
	const [queryOptions, setQueryOptions] =
		useState<QueryHookOptions<TData, TVariables>>();
	const queryResponse = useQuery(queryDoc, {
		skip: !queryOptions,
		...queryOptions,
	});

	const [queryPreviewOptions, setQueryPreviewOptions] =
		useState<QueryHookOptions<TPreviewData, TPreviewVariables>>();
	const queryPreviewResponse = useQuery(queryPreviewDoc, {
		skip: !queryPreviewOptions,
		...queryPreviewOptions,
	});

	useNotifyError(queryResponse.error || queryPreviewResponse.error);

	const dataResolver = useMemo(
		() => () => resolver(queryResponse.data),
		[resolver, queryResponse.data],
	);
	const dataNearbyResolver = useMemo(
		() => nearbyResolver && (() => nearbyResolver(queryResponse.data)),
		[nearbyResolver, queryResponse.data],
	);
	const dataPreviewResolver = useMemo(
		() => () => previewResolver(queryPreviewResponse.data),
		[previewResolver, queryPreviewResponse.data],
	);

	const mapProviderValue = useMemo(() => {
		return {
			queryResponse,
			queryOptions,
			setQueryOptions,
			dataResolver,
			dataNearbyResolver,
			queryPreviewResponse,
			queryPreviewOptions,
			setQueryPreviewOptions,
			dataPreviewResolver,
		};
	}, [
		queryResponse,
		queryOptions,
		setQueryOptions,
		dataResolver,
		dataNearbyResolver,
		queryPreviewResponse,
		queryPreviewOptions,
		setQueryPreviewOptions,
		dataPreviewResolver,
	]);

	return (
		// TODO: add generics
		<MapContext.Provider
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			value={mapProviderValue as MapContextValue<any, any, any, any, any>}
		>
			{children}
		</MapContext.Provider>
	);
};

export default MapProvider;

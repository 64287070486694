import RouteLink from '@ivy/components/atoms/RouteLink';
import { type MapCardDataObject } from '@ivy/components/molecules/MapCard';
import { EMRATypes } from '@ivy/constants/emra';
import { FACILITY_PLACEHOLDER_IMAGE } from '@ivy/constants/facility';
import { gql } from '@ivy/gql/types';
import { getFragmentData } from '@ivy/gql/types';
import { buildInternalLink } from '@ivy/lib/util/route';

export interface FeaturedCard extends MapCardDataObject {
	id: string;
	distance: number;
}

export const TrainingFeatured_FeaturedResidencyQDoc = gql(/* GraphQL */ `
	query TrainingFeatured_FeaturedResidency(
		$x: float8!
		$y: float8!
		$limit: Int!
	) {
		featuredResidency: featured_residency_dist(
			args: { x: $x, y: $y }
			order_by: { distance: asc }
			limit: $limit
		) {
			id
			distance
			residency {
				id
				training {
					id
					slug
				}
				latestSurvey: latest_survey {
					id
					survey {
						id
						trainingSurvey: training_survey {
							id
							...TrainingFeatured_TrainingSurvey
						}
					}
				}
			}
		}
	}
`);

export const TrainingFeatured_FeaturedClerkshipQDoc = gql(/* GraphQL */ `
	query TrainingFeatured_FeaturedClerkship(
		$x: float8!
		$y: float8!
		$limit: Int!
	) {
		featuredClerkship: featured_clerkship_dist(
			args: { x: $x, y: $y }
			order_by: { distance: asc }
			limit: $limit
		) {
			id
			distance
			clerkship {
				id
				training {
					id
					slug
				}
				latestSurvey: latest_survey {
					id
					survey {
						id
						trainingSurvey: training_survey {
							id
							...TrainingFeatured_TrainingSurvey
						}
					}
				}
			}
		}
	}
`);

export const TrainingFeatured_FeaturedFellowshipQDoc = gql(/* GraphQL */ `
	query TrainingFeatured_FeaturedFellowship(
		$x: float8!
		$y: float8!
		$limit: Int!
	) {
		featuredFellowship: featured_fellowship_dist(
			args: { x: $x, y: $y }
			order_by: { distance: asc }
			limit: $limit
		) {
			id
			distance
			fellowship {
				id
				training {
					id
					slug
				}
				latestSurvey: latest_survey {
					id
					survey {
						id
						trainingSurvey: training_survey {
							id
							...TrainingFeatured_TrainingSurvey
						}
					}
				}
			}
		}
	}
`);

export const TrainingFeatured_TrainingSurveyFDoc = gql(/* GraphQL */ `
	fragment TrainingFeatured_TrainingSurvey on training_survey {
		id
		city
		state
		residencySurvey: residency_survey {
			id
			residency {
				id
				training {
					id
					name
				}
			}
			programLength: program_length
			primaryTrainingSite: primary_training_site
			specialty
		}
		clerkshipSurvey: clerkship_survey {
			id
			clerkship {
				id
				training {
					id
					name
				}
			}
			residency {
				id
				training {
					id
					name
					slug
				}
			}
			comlexRequired: comlex_required
			rotationTypes: rotation_types
			vsloParticipant: vslo_participant
		}
		fellowshipSurvey: fellowship_survey {
			id
			fellowship {
				id
				training {
					id
					name
				}
			}
			residency {
				id
				training {
					id
					name
					slug
				}
			}
			advancedDegrees: advanced_degrees
			moonlightingAllowed: moonlighting_allowed
			programLength: program_length
			shiftsPerMonth: shifts_per_month
			subspecialty
		}
		trainingSites: training_sites(where: { primary: { _eq: true } }) {
			facility {
				picture {
					publicUrl: public_url
				}
			}
		}
	}
`);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const resolver = (rawData: any, type: string): FeaturedCard => {
	let newObject: FeaturedCard = {
		id: rawData.id,
		distance: rawData.distance,
		picture: FACILITY_PLACEHOLDER_IMAGE,
		stats: [],
	};

	switch (type) {
		case EMRATypes.CLERKSHIP:
			const clerkship = getFragmentData(
				TrainingFeatured_TrainingSurveyFDoc,
				rawData.latestSurvey?.survey?.trainingSurvey,
			);

			newObject.stats = [
				{
					label: 'Participates in VSLO:',
					value:
						typeof clerkship.clerkshipSurvey?.vsloParticipant === 'boolean'
							? clerkship.clerkshipSurvey.vsloParticipant
								? 'Yes'
								: 'No'
							: 'N/A',
				},
				{
					label: 'USMLE Step 1/COMLEX Required:',
					value: clerkship.clerkshipSurvey?.comlexRequired,
				},
				{
					label: 'Rotation Types:',
					value: clerkship.clerkshipSurvey?.rotationTypes.join(', '),
				},
			];

			if (clerkship.clerkshipSurvey?.residency?.id) {
				newObject.stats.push({
					label: 'Residency:',
					value: clerkship.clerkshipSurvey.residency.training.name,
					isLink: true,
					linkProps: {
						to: {
							pathname: buildInternalLink(
								RouteLink.routes.EMRA_RESIDENCY_SHOW,
								{
									trainingId: [
										clerkship.clerkshipSurvey?.residency?.id,
										clerkship.clerkshipSurvey?.residency?.training.slug,
									],
								},
							),
						},
						state: {
							backNav: {
								target: 'home',
							},
						},
						openInNewTab: true,
					},
				});
			}

			const clerkPathName = buildInternalLink(
				RouteLink.routes.EMRA_CLERKSHIP_SHOW,
				{
					trainingId: [rawData.id, rawData.training.slug],
				},
			);

			newObject = {
				...newObject,
				title: clerkship.clerkshipSurvey?.clerkship?.training.name,
				locationInfo: `${clerkship.city}, ${clerkship.state}`,
				picture:
					(clerkship.trainingSites.length > 0 &&
						clerkship.trainingSites[0].facility?.picture?.publicUrl) ||
					FACILITY_PLACEHOLDER_IMAGE,
				pathname: clerkPathName,
				linkProps: {
					to: {
						pathname: buildInternalLink(RouteLink.routes.EMRA_CLERKSHIP_SHOW, {
							trainingId: [rawData.id, rawData.training.slug],
						}),
					},
					state: {
						backNav: {
							target: 'search',
						},
					},
				},
			};
			break;
		case EMRATypes.FELLOWSHIP:
			const fellowship = getFragmentData(
				TrainingFeatured_TrainingSurveyFDoc,
				rawData.latestSurvey?.survey?.trainingSurvey,
			);

			newObject.stats = [
				{
					label: 'Training Opportunity:',
					value: fellowship.fellowshipSurvey?.subspecialty,
				},
				{
					label: 'Advanced Degrees Offered:',
					value: fellowship.fellowshipSurvey?.advancedDegrees.join(', '),
				},
				{
					label: 'Is Moonlighting Allowed:',
					value: fellowship.fellowshipSurvey?.moonlightingAllowed,
				},
				{
					label: 'Program Length:',
					value: fellowship.fellowshipSurvey?.programLength,
				},
				{
					label: 'Shifts per Month:',
					value: fellowship.fellowshipSurvey?.shiftsPerMonth,
				},
			];

			if (fellowship.fellowshipSurvey?.residency?.id) {
				newObject.stats.push({
					label: 'Residency:',
					value: fellowship.fellowshipSurvey.residency.training.name,
					isLink: true,
					linkProps: {
						to: {
							pathname: buildInternalLink(
								RouteLink.routes.EMRA_RESIDENCY_SHOW,
								{
									trainingId: [
										fellowship.fellowshipSurvey?.residency?.id,
										fellowship.fellowshipSurvey?.residency?.training.slug,
									],
								},
							),
						},
						state: {
							backNav: {
								target: 'home',
							},
						},
						openInNewTab: true,
					},
				});
			}

			const fellPathname = buildInternalLink(
				RouteLink.routes.EMRA_FELLOWSHIP_SHOW,
				{
					trainingId: [rawData.id, rawData.training.slug],
				},
			);

			newObject = {
				...newObject,
				title: fellowship.fellowshipSurvey?.fellowship?.training.name,
				locationInfo: `${fellowship.city}, ${fellowship.state}`,
				picture:
					(fellowship.trainingSites.length > 0 &&
						fellowship.trainingSites[0].facility?.picture?.publicUrl) ||
					FACILITY_PLACEHOLDER_IMAGE,
				pathname: fellPathname,
				linkProps: {
					to: {
						pathname: fellPathname,
					},
					state: {
						backNav: {
							target: 'search',
						},
					},
				},
			};
			break;
		case EMRATypes.RESIDENCY:
		default:
			const res = getFragmentData(
				TrainingFeatured_TrainingSurveyFDoc,
				rawData.latestSurvey?.survey?.trainingSurvey,
			);

			const resPathname = buildInternalLink(
				RouteLink.routes.EMRA_RESIDENCY_SHOW,
				{
					trainingId: [rawData.id, rawData.training.slug],
				},
			);

			newObject = {
				...newObject,
				title: res.residencySurvey?.residency?.training.name,
				locationInfo: `${res.city}, ${res.state}`,
				picture:
					(res.trainingSites.length > 0 &&
						res.trainingSites[0].facility?.picture?.publicUrl) ||
					FACILITY_PLACEHOLDER_IMAGE,
				pathname: resPathname,
				linkProps: {
					to: {
						pathname: resPathname,
					},
					state: {
						backNav: {
							target: 'search',
						},
					},
				},
				stats: [
					{
						label: 'Program Length:',
						value: `${res.residencySurvey?.programLength || 'N/A'} year(s)`,
					},
					{
						label: 'Specialties:',
						value: res.residencySurvey?.specialty.join(', '),
					},
					{
						label: 'Primary Training Site:',
						value: res.residencySurvey?.primaryTrainingSite,
					},
				],
			};
			break;
	}
	return newObject;
};

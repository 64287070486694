import React from 'react';

import { Box, Typography } from '@mui/material';

import MapCard from '@ivy/components/molecules/MapCard';
import FacilityCard from '@ivy/components/organisms/FacilityCard';
import { FACILITY_PLACEHOLDER_IMAGE } from '@ivy/constants/facility';
import { gql, getFragmentData, type FragmentType } from '@ivy/gql/types';

const EmraProgramPrimarySite_TrainingSurveyFDoc = gql(/* GraphQL */ `
	fragment EmraProgramPrimarySite_TrainingSurvey on training_survey {
		id
		type
		primaryTrainingSites: training_sites(where: { primary: { _eq: true } }) {
			id
			primary
			facility {
				id
				...FacilityCard_Facility
			}
			facilityOther: facility_other
		}
	}
`);

interface EmraProgramPrimarySiteProps {
	survey: FragmentType<typeof EmraProgramPrimarySite_TrainingSurveyFDoc>;
}

const EmraProgramPrimarySite = ({
	survey: rawSurvey,
}: EmraProgramPrimarySiteProps) => {
	const survey = getFragmentData(
		EmraProgramPrimarySite_TrainingSurveyFDoc,
		rawSurvey,
	);

	const fac = survey?.primaryTrainingSites[0]?.facility;
	const facOther = survey?.primaryTrainingSites[0]?.facilityOther;

	if (!fac && !facOther) {
		return null;
	}

	return (
		<Box component='section'>
			<Typography
				variant='h5'
				component='h2'
				sx={{ mb: 5, fontWeight: 'light' }}
			>
				Primary Training Site
			</Typography>
			{fac ? (
				<FacilityCard
					facility={fac}
					hideRating
					hideBadge
					hideJobPosting
					sx={{
						borderRadius: '16px',
						boxShadow: '0px 16px 40px 0px rgba(142, 149, 155, 0.30)',
						maxWidth: '723px',
					}}
					openInNewTab
					defaultTo='default'
					backNavTarget={survey.type.toLowerCase()}
				/>
			) : (
				<MapCard
					entity={{
						title: facOther,
						picture: FACILITY_PLACEHOLDER_IMAGE,
						stats: [
							{
								label: 'Facility Type:',
								value: 'N/A',
							},
							{
								label: 'EM Residency:',
								value: 'N/A',
							},
							{
								label: 'Annual ED Visits:',
								value: 'N/A',
							},
						],
						locationInfo: '- -',
					}}
					sx={{
						borderRadius: '16px',
						boxShadow: '0px 16px 40px 0px rgba(142, 149, 155, 0.30)',
						maxWidth: '723px',
					}}
				/>
			)}
		</Box>
	);
};

export default EmraProgramPrimarySite;

import React from 'react';

import { filterValidData } from '@ivy/components/molecules/FormPopup';
import { CLN_SKILLS } from '@ivy/constants/clinician';
import { gql } from '@ivy/gql/types';
import { type ProfileCompleteForm_ClinicianFragment } from '@ivy/gql/types/graphql';
import {
	type SubmitFunction,
	type FormStepObj,
} from '@ivy/lib/forms/formFormatHelpers';

import ProfileStep, {
	validation as profileValidation,
	initialValues as profileInitialValue,
	type ProfileStepValues,
	type TrainingObj,
} from './ProfileStep';
import SkillsStep, {
	validation as skillsValidation,
	initialValues as skillsInitialValue,
	type SkillsStepValues,
} from './SkillsStep';

type ProfileCompleteFormValues = ProfileStepValues & SkillsStepValues;

const ProfileCompleteForm_ClinicianFDoc = gql(/* GraphQL */ `
	fragment ProfileCompleteForm_Clinician on clinician {
		id
		profileComplete: profile_complete
		fid
		profDegree: prof_degree
		creds
		isAPP @client
		cv {
			id
			filename
			publicUrl: public_url
		}
		schoolName: school_name
		gradYr: grad_yr
		currStudent: current_student

		skillEkgInterpret: skill_ekg_interpret
		skillEkgStemi: skill_ekg_stemi
		skillEyeFb: skill_eye_fb
		skillEarFb: skill_ear_fb
		skillWoundRepair: skill_wound_repair
		skillOral: skill_oral
		skillPeds: skill_peds
		skillFractureBlocks: skill_fracture_blocks
		skillJointReduction: skill_joint_reduction
		skillNeonates: skill_neonates
		skillObgyn: skill_obgyn
		skillIntubation: skill_endotracheal_intubation
		skillChestTube: skill_chest_tube_placement
		skillChestNeedle: skill_chest_needle
		skillVenous: skill_central_venous_line_placement
		skillIOAccess: skill_io_access
		skillLumbar: skill_lumbar_puncture
		skillSedation: skill_procedural_sedation
		skillUSDiagnostics: skill_us_diagnostics
		skillUSProcedures: skill_us_procedures

		specialties(order_by: [{ primary: desc }, { name: asc }]) {
			id
			primary
			name
		}
		licenses(order_by: { state: asc }) {
			id
			state
		}
		fsmbBio: fsmb_bio {
			...ClinicianSearch_FsmbBio
		}
		training {
			id
			program
			field
			startYear: start_yr
			endYear: end_yr
			present
			type
		}
		appCerts: app_certs(order_by: { name: asc }) {
			id
			name
		}
		boardCerts: board_certs(order_by: { cert: { field: asc } }) {
			id
			eligible
			cert {
				id
				field
				board {
					id
					name
				}
			}
		}
		spExp: sp_exp {
			id
			field
			level
		}
		account {
			id
			pi: personal_info {
				id
				firstName: first_name
				lastName: last_name
				fullName: full_name
			}
			picture {
				id
				filename
				publicUrl: public_url
			}
		}
	}
`);

const ProfileCompleteForm_UpdateProfileSkillsMDoc = gql(/* GraphQL */ `
	mutation ProfileCompleteForm_UpdateProfileSkills(
		$clinicianId: uuid!
		$input: clinician_set_input!
	) {
		update_clinician_by_pk(pk_columns: { id: $clinicianId }, _set: $input) {
			id
		}
	}
`);

const ProfileCompleteForm_UpdateProfileMDoc = gql(/* GraphQL */ `
	mutation ProfileCompleteForm_UpdateProfile(
		$clinicianId: uuid!
		$specialties: [clinician_specialty_insert_input!]!
		$creds: String!
		$cvId: uuid!
		$pictureId: uuid!
		$appCerts: [clinician_app_cert_insert_input!]!
		$spExp: [clinician_sp_exp_insert_input!]!
		$boardCerts: [clinician_board_cert_insert_input!]!
		$licenses: [clinician_license_insert_input!]!
		$training: [clinician_training_insert_input!]!
		$schoolName: String!
		$gradYr: Int
	) {
		delete_clinician_specialty(where: { clinician_id: { _eq: $clinicianId } }) {
			returning {
				id
			}
		}
		insert_clinician_specialty(objects: $specialties) {
			returning {
				id
			}
		}

		delete_clinician_app_cert(where: { clinician_id: { _eq: $clinicianId } }) {
			returning {
				id
			}
		}
		insert_clinician_app_cert(objects: $appCerts) {
			returning {
				id
			}
		}

		delete_clinician_sp_exp(where: { clinician_id: { _eq: $clinicianId } }) {
			returning {
				id
			}
		}
		insert_clinician_sp_exp(objects: $spExp) {
			returning {
				id
			}
		}

		delete_clinician_board_cert(
			where: { clinician_id: { _eq: $clinicianId } }
		) {
			returning {
				id
			}
		}
		insert_clinician_board_cert(objects: $boardCerts) {
			returning {
				id
			}
		}

		delete_clinician_license(where: { clinician_id: { _eq: $clinicianId } }) {
			returning {
				id
			}
		}
		insert_clinician_license(objects: $licenses) {
			returning {
				id
			}
		}

		delete_clinician_training(where: { clinician_id: { _eq: $clinicianId } }) {
			returning {
				id
			}
		}
		insert_clinician_training(objects: $training) {
			returning {
				id
			}
		}

		update_clinician_by_pk(
			pk_columns: { id: $clinicianId }
			_set: {
				school_name: $schoolName
				grad_yr: $gradYr
				creds: $creds
				cv_id: $cvId
				profile_complete: true
			}
		) {
			id
			profileComplete: profile_complete
		}

		update_current_account(
			where: { id: { _eq: $clinicianId } }
			_set: { picture_id: $pictureId }
		) {
			returning {
				id
			}
		}
	}
`);

interface ExtraParams {
	clinician: ProfileCompleteForm_ClinicianFragment;
}

const profileSubmit: SubmitFunction<
	ProfileCompleteFormValues,
	ExtraParams
> = async (
	validation,
	values,
	actions,
	client,
	onSuccess,
	onError,
	params,
	refetchQueries,
) => {
	const validData = validation ? filterValidData(values, validation) : values;

	try {
		const training: TrainingObj[] = [];
		if (validData.residency) training.push(...validData.residency);
		if (validData.fellowship) training.push(...validData.fellowship);
		if (validData.appTraining) training.push(...validData.appTraining);

		await client.mutate({
			mutation: ProfileCompleteForm_UpdateProfileMDoc,
			variables: {
				clinicianId: params?.clinician?.id,
				creds: validData.creds,
				cvId: validData.cv ? validData.cv.id : null,
				pictureId: validData.picture ? validData.picture.id : null,
				appCerts: validData.appCerts
					? validData.appCerts.map(({ name }) => ({
							name,
							clinician_id: params?.clinician?.id,
					  }))
					: [],
				spExp: validData.spExp
					? validData.spExp.map(({ field, level }) => ({
							clinician_id: params?.clinician?.id,
							field,
							level,
					  }))
					: [],
				boardCerts: validData.boardCerts
					? validData.boardCerts.map(({ eligible, cert }) => ({
							eligible,
							cert_id: cert?.id,
							clinician_id: params?.clinician?.id,
					  }))
					: [],
				licenses: validData.licenses
					? validData.licenses.map(({ state }) => ({
							state,
					  }))
					: [],
				specialties: validData.specialties
					? validData.specialties.map(({ name, primary }) => ({
							// MUST destructure, otherwise get some weird __typename error missing from input insert_clinician_specialty
							name,
							primary,
							clinician_id: params?.clinician?.id,
					  }))
					: [],
				schoolName: validData.schoolName,
				gradYr: validData.currStudent ? null : validData.gradYr?.getFullYear(),
				training: training.map(
					({ program, type, field, startYear, endYear, present }) => ({
						clinician_id: params?.clinician?.id,
						program,
						field,
						type,
						start_yr: startYear?.getFullYear(),
						end_yr: present ? null : endYear?.getFullYear(),
					}),
				),
			},
			refetchQueries,
			awaitRefetchQueries: !!refetchQueries,
		});
		actions?.setSubmitting(false);

		onSuccess('Your profile has been updated');
	} catch (e) {
		actions?.setSubmitting(false);
		onError(e, { extra: values }, 'An error occurred, please try again.');
	}
};

const skillsSubmit: SubmitFunction<
	ProfileCompleteFormValues,
	ExtraParams
> = async (
	validation,
	values,
	actions,
	client,
	onSuccess,
	onError,
	params,
	refetchQueries,
) => {
	const { skills } = values;
	try {
		await client.mutate({
			mutation: ProfileCompleteForm_UpdateProfileSkillsMDoc,
			variables: {
				clinicianId: params?.clinician?.id,
				input: CLN_SKILLS.reduce(
					(tot, { apiKey, key }) => ({
						...tot,
						[apiKey]: skills[key],
					}),
					{},
				),
			},
			refetchQueries,
			awaitRefetchQueries: !!refetchQueries,
		});
		actions?.setSubmitting(false);
		onSuccess();
	} catch (e) {
		actions?.setSubmitting(false);
		onError(e);
	}
};

const profileGraphic = {
	img: 'https://assets.ivyclinicians.io/images/woman-chair-sitting-illustration.svg',
	imgSx: {
		maxWidth: 'min(320px, 100%)',
	},
	title: `“Tell me about yourself.”`,
	chip: '🕐 Takes 3-4 minutes',
	subtitle:
		'The classic job interview opener. We promise to make it quick and worth your while.',
	buttonText: `Let's Start`,
};

const skillGraphic = {
	img: 'https://assets.ivyclinicians.io/images/clinician-beaker-illustration.svg',
	imgSx: {
		maxWidth: 'min(370px, 100%)',
	},
	title: `It’s time to shine and show off a bit`,
	chip: '🕐 3-4 minutes',
	subtitle: (
		<>
			We put together a list of skills and procedures that will impress your
			future employer. Scroll through and{' '}
			<strong>select the ones you can perform independently.</strong>
		</>
	),
	buttonText: `Let's Start`,
};

const steps: FormStepObj<ProfileCompleteFormValues, ExtraParams>[] = [
	{
		key: 'profile',
		title: 'Profile',
		submit: profileSubmit,
		component: ProfileStep,
		validate: profileValidation,
		initialValue: profileInitialValue,
		nextStep: () => 'skills',
		finalStep: (values, extra) => !extra.clinician.isAPP,
		closeStep: (values, extra) => !extra.clinician.isAPP,
		graphicContent: profileGraphic,
	},
	{
		key: 'skills',
		shouldShow: (params) => !!params.clinician.isAPP,
		title: 'Skills & procedures',
		submit: skillsSubmit,
		component: SkillsStep,
		validate: skillsValidation,
		initialValue: skillsInitialValue,
		prevStep: () => 'profile',
		finalStep: () => true,
		closeStep: () => true,
		graphicContent: skillGraphic,
	},
];

export {
	type ProfileCompleteFormValues,
	type ExtraParams,
	ProfileCompleteForm_ClinicianFDoc,
	steps,
};

import React from 'react';

import {
	Box,
	SvgIcon,
	type SxProps,
	type Theme,
	Typography,
} from '@mui/material';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';

import { formatInteger } from '@ivy/lib/formatting/number';
import { combineSx } from '@ivy/lib/styling/sx';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

export interface HeroProps {
	sx?: SxProps<Theme>;
	facCount: number;
	orgCount: number;
}

const HowItWorks = ({ sx, facCount, orgCount }: HeroProps) => {
	return (
		<Box
			component='section'
			sx={combineSx(
				{
					position: 'relative',
					overflow: 'hidden',
					display: {
						md: 'flex',
					},
					justifyContent: {
						md: 'center',
					},
					alignItems: {
						md: 'center',
					},
				},
				sx,
			)}
		>
			<Box
				sx={{
					position: 'absolute',
					top: 0,
					bottom: '85%',
					left: 0,
					right: 0,
					display: 'flex',
					justifyContent: 'center',
					zIndex: 1,
				}}
			>
				<SvgIcon
					width='1950'
					height='220'
					viewBox='0 0 1950 220'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					preserveAspectRatio='none'
					sx={(theme) => ({
						width: '1920px',
						height: '100%',
						[theme.breakpoints.up(1920)]: {
							width: '100%',
						},
					})}
				>
					<path
						d='M925.565 54.5547C1350.57 -117.445 1949.57 219.555 1949.57 219.555V0H0.0644531C1.92663 82.1904 0.0651131 129.055 0.0651131 129.055C0.0651131 129.055 500.565 226.555 925.565 54.5547Z'
						fill='#CDEFDC'
					/>
				</SvgIcon>
			</Box>
			<Box
				sx={{
					pt: 20,
					maxWidth: '720px',
					mx: 'auto',
					px: 3,
					height: '100%',
					position: 'relative',
					zIndex: '2',
					display: 'flex',
					justifyContent: 'center',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Typography variant='h2' mb={5} align='center'>
					How does it work?
				</Typography>
				<Typography variant='body1' mb={5} color='text.icon' align='center'>
					Ivy is the only job board where clinicians can apply to all{' '}
					{formatInteger(facCount)} emergency departments and{' '}
					{formatInteger(orgCount)} employers. Watch the video below to learn
					how you can use Ivy to recruit more clinicians and fill your staffing
					needs.
				</Typography>
				<Box
					sx={{
						width: '100%',
						aspectRatio: '1024 / 720',
						maxWidth: {
							xs: '500px',
							md: 'none',
						},
						overflow: 'hidden',
						borderRadius: (theme) => `${theme.shape.borderRadius}px`,
						'& .yt-lite': {
							width: '100%',
							height: '100%',
							border: 'none',
						},
					}}
				>
					<LiteYouTubeEmbed
						id='XVoT_wyB8MA'
						title='How Ivy Clinicians Works - For Employers'
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default HowItWorks;

import React from 'react';

import { Check, Replay } from '@mui/icons-material';
import {
	Button,
	type ButtonProps,
	IconButton,
	Stack,
	type SxProps,
	type Theme,
	Typography,
} from '@mui/material';

import { formatInteger } from '@ivy/lib/formatting/number';
import { combineSx } from '@ivy/lib/styling/sx';

export interface ApplyFloaterProps<T> {
	sx?: SxProps<Theme>;
	selected: T[];
	onClickReset: () => void;
	onClickApply: ButtonProps['onClick'];
	mobile?: boolean;
}

const ApplyFloater = <T,>({
	selected,
	onClickReset,
	onClickApply,
	sx,
	mobile = false,
}: ApplyFloaterProps<T>) => {
	return (
		<Stack
			direction='row'
			sx={combineSx(
				{
					boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
					alignItems: 'center',
					borderRadius: '999px',
					px: mobile ? 3 : 5,
					py: mobile ? 2 : 3,
					bgcolor: (theme) => theme.palette.light4.main,
				},
				sx,
			)}
		>
			{mobile ? (
				<IconButton
					color='primary'
					disabled={!selected.length}
					onClick={onClickReset}
					sx={{
						mr: 1,
					}}
				>
					<Replay />
				</IconButton>
			) : (
				<Button
					color='primary'
					startIcon={<Replay />}
					disabled={!selected.length}
					onClick={onClickReset}
					sx={{
						// Already has some padding, so less margin than usual (3)
						mr: 2,
						width: 'max-content',
					}}
				>
					Reset selection
				</Button>
			)}
			<Typography
				variant={mobile ? 'body2' : 'body1'}
				sx={{
					width: 'max-content',
					display: 'flex',
					alignItems: 'center',
					color: selected.length ? 'primary.main' : 'text.disabled',
					fontWeight: 'bold',
					mr: 3,
				}}
			>
				<Check
					sx={{
						color: 'inherit',
						mr: 0.5,
					}}
				/>
				{formatInteger(selected.length)} selected
			</Typography>
			<Button
				variant='contained'
				color='primary'
				onClick={onClickApply}
				size='large'
				disabled={!selected.length}
				sx={{
					width: 'max-content',
				}}
			>
				{mobile
					? 'Apply'
					: `Apply to EM ${selected.length > 1 ? 'practices' : 'practice'}`}
			</Button>
		</Stack>
	);
};

export default ApplyFloater;

import React from 'react';

import { Help as HelpIcon } from '@mui/icons-material';
import {
	Box,
	type BoxProps,
	type SxProps,
	type Theme,
	Typography,
	type TypographyProps,
	Tooltip,
} from '@mui/material';

import { combineSx } from '@ivy/lib/styling/sx';

export interface LabeledTypographyProps extends BoxProps {
	label?: string;
	text: string | React.ReactNode;
	tooltipContent?: string;
	icon?: React.ReactNode;
	labelProps?: TypographyProps<'div'>;
	textProps?: TypographyProps<'div'>;
	disabled?: boolean;
	sx?: SxProps<Theme>;
}

const LabeledTypography = ({
	label,
	text,
	icon,
	labelProps,
	tooltipContent,
	textProps,
	disabled = false,
	sx,
	...props
}: LabeledTypographyProps) => {
	return (
		<Box
			sx={combineSx(
				{
					display: icon ? 'flex' : undefined,
					alignItems: icon ? 'center' : undefined,
					opacity: disabled ? 0.4 : undefined,
				},
				sx,
			)}
			{...props}
		>
			<Box
				sx={{
					flex: icon ? '0 0 auto' : undefined,
					display: 'flex',
					alignItems: 'center',
					fontSize: '1.5rem',
					color: 'text.icon',
				}}
			>
				{icon}
			</Box>
			<Box ml={icon ? 2 : undefined} flex={icon ? '1 1 auto' : undefined}>
				{!!label && (
					<Typography component='div' variant='caption' mb={1} {...labelProps}>
						{label}
						{tooltipContent && (
							<Tooltip title={tooltipContent} placement='top'>
								<HelpIcon sx={{ fontSize: '0.9rem', ml: 0.3, mb: '-3px' }} />
							</Tooltip>
						)}
					</Typography>
				)}
				<Typography component='div' variant='body1' {...textProps}>
					{text}
				</Typography>
			</Box>
		</Box>
	);
};

export default LabeledTypography;

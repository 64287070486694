import {
	OWNERSHIP_DESCRIPTION,
	OwnershipStructure,
} from '@ivy/constants/organization';

export const ADULT_TRAUMA_OPTS = [
	{
		label: 'Adult I',
		value: '1',
	},
	{
		label: 'Adult II',
		value: '2',
	},
	{
		label: 'Adult III',
		value: '3',
	},
	{
		label: 'Adult IV',
		value: '4',
	},
	{
		label: 'Adult V',
		value: '5',
	},
	{
		label: 'No Designation',
		value: 'none',
	},
];

export const PED_TRAUMA_OPTS = [
	{
		label: 'Pediatric I',
		value: '1',
	},
	{
		label: 'Pediatric II',
		value: '2',
	},
	{
		label: 'Pediatric III',
		value: '3',
	},
	{
		label: 'No Designation',
		value: 'none',
	},
];

export const OWNERSHIP_OPTS = Object.values(OwnershipStructure).map((el) => ({
	label: OWNERSHIP_DESCRIPTION[el],
	value: el,
}));

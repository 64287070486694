export enum SiteMapCategories {
	STATE = 'state',
	CITY = 'city',
}

export const SitemapTabs = [
	{
		category: SiteMapCategories.STATE,
		label: 'By state',
	},
	{
		category: SiteMapCategories.CITY,
		label: 'By city',
	},
];

import React from 'react';

import { SubStep } from '@ivy/components/molecules/FormPopup';
import {
	formatForm,
	type FormStepProps,
	type SubStepObj,
} from '@ivy/lib/forms/formFormatHelpers';

import { type GeneralStepValues } from '../GeneralStep';
import { type ExtraParams } from '../reviewForm';

import Rating, {
	type RatingValues,
	employerValidation,
	facilityValidation,
	initialValue as ratingInitialValue,
} from './Rating';

type ReviewStepValues = RatingValues & GeneralStepValues;

const steps: SubStepObj<ReviewStepValues, ExtraParams>[] = [
	{
		key: 'employerRating',
		label: (values) => `How would you rate ${values.org?.name}? (employer)`,
		component: Rating,
		validate: employerValidation,
		initialValue: ratingInitialValue,
		namespace: 'employerRating',
	},
	{
		key: 'facilityRating',
		label: (values) =>
			`How would you rate ${values.facility?.name}? (facility)`,
		component: Rating,
		validate: facilityValidation,
		namespace: 'facilityRating',
	},
];

const { formSteps, initialValues, validation } = formatForm(steps);
const ReviewStep = (props: FormStepProps<ReviewStepValues, ExtraParams>) => (
	<SubStep {...props} subSteps={formSteps} />
);
export default ReviewStep;
export { type ReviewStepValues, validation, initialValues };

import React from 'react';

import {
	Box,
	SvgIcon,
	type SxProps,
	type Theme,
	Typography,
} from '@mui/material';

import { combineSx } from '@ivy/lib/styling/sx';

export interface ValueProp1Props {
	sx?: SxProps<Theme>;
}

const ValueProp1 = ({ sx }: ValueProp1Props) => {
	return (
		<Box
			component='section'
			sx={combineSx(
				{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					bgcolor: '#CDEFDC',
				},
				sx,
			)}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: {
						xs: 'column-reverse',
						md: 'row',
					},
					maxWidth: '1400px',
					alignItems: 'center',
					px: 3,
				}}
			>
				<Box
					sx={{
						flexBasis: {
							md: '60%',
						},
						width: {
							xs: '100%',
							md: 'initial',
						},
						maxWidth: {
							xs: '500px',
							md: 'none',
						},
						position: 'relative',
						aspectRatio: '1 / 0.9696',
						mt: {
							xs: 5,
							md: 0,
						},
					}}
				>
					<SvgIcon
						width='620'
						height='833'
						viewBox='0 0 620 833'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
						sx={{
							position: 'absolute',
							zIndex: 1,
							width: 'auto',
							height: '100%',
							left: {
								xs: '50%',
								md: '23.18%',
							},
							transform: {
								xs: 'translateX(-50%)',
								md: 'initial',
							},
							top: 0,
						}}
					>
						<path
							opacity='0.1'
							d='M557.562 40.5836C499.709 -4.62508 410.985 -6.31927 334.273 7.90465C290.166 16.083 260.861 44.1499 230.847 72.8954C218.034 85.1667 205.093 97.5617 190.815 108.586C174.53 121.16 155.406 131.972 136.451 142.69C94.4066 166.461 53.19 189.765 45.6111 230.789C36.6621 279.228 62.915 325.989 100.259 364.984C66.3367 394.89 37.6937 432.498 17.307 474.975C-38.2067 590.64 47.6808 713.743 163.346 769.256C305.046 837.266 496.567 879.339 564.577 737.638C612.393 638.013 593.5 530.681 534.216 444.666C544.688 439.659 554.549 433.562 563.642 426.173C621.861 378.865 620.647 303.007 619.546 234.228C619.528 233.08 619.509 231.933 619.491 230.789C618.413 162.396 616.731 86.8214 557.562 40.5836Z'
							fill='#1B8374'
						/>
					</SvgIcon>
					<Box
						component='img'
						src='https://assets.ivyclinicians.io/gifs/landing-page%3Aemployer%3Afullpic%3Acropped.gif'
						sx={(theme) => ({
							position: 'absolute',
							zIndex: 2,
							top: '16.41%',
							height: '67.17%',
							borderRadius: '32px',
							boxShadow: '0px 16px 40px rgba(142, 149, 155, 0.3)',
							[theme.breakpoints.between('xs', 'md')]: {
								left: '50%',
								transform: 'translate(-50%)',
							},
							[theme.breakpoints.up('md')]: {
								left: 0,
								width: '78.74%',
							},
						})}
					/>
				</Box>
				<Box
					sx={{
						flexBasis: {
							md: '40%',
						},
						ml: {
							md: 10,
						},
					}}
				>
					<Typography
						variant='h2'
						sx={{
							lineHeight: 1.3,
							mb: 5,
							textAlign: {
								xs: 'center',
								md: 'initial',
							},
						}}
					>
						Give clinicians the{' '}
						<Box
							component='span'
							sx={{
								position: 'relative',
								whiteSpace: 'nowrap',
							}}
						>
							full picture
							<SvgIcon
								width='268'
								height='21'
								viewBox='0 0 268 21'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
								sx={{
									position: 'absolute',
									top: '90%',
									left: 0,
									width: '100%',
									height: 'auto',
								}}
							>
								<path
									d='M245.38 7.39948C233.421 6.03935 247.192 -4.58285 245.5 6.80667C245.47 7.00832 245.43 7.2059 245.38 7.39948ZM245.38 7.39948C249.373 7.85365 256.235 7.27511 267.5 4.49952M245.38 7.39948C241.66 21.7909 182.969 14.0675 143.5 9.87157C103.5 5.61922 6.5 6.80667 1.5 20.4994'
									stroke='#1EC86A'
									strokeWidth='2'
									fill='none'
								/>
							</SvgIcon>
						</Box>{' '}
						of your practice
					</Typography>
					<Typography
						variant='body1'
						sx={{
							color: 'text.icon',
							textAlign: {
								xs: 'center',
								md: 'initial',
							},
						}}
					>
						All of your facilities, whether actively recruiting or building
						networks for future vacancies, are already showcased on Ivy's{' '}
						<b>free employer directory</b> with the information clinicians are
						looking for, including location, ED visits, EHR, and more.
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};

export default ValueProp1;

import React from 'react';

import { Helmet } from 'react-helmet';
import { Navigate, useParams } from 'react-router-dom';

import { RunningToWorkIcon } from '@ivy/components/icons';
import NavTemplate from '@ivy/components/templates/NavTemplate';
import { SLUG2PROFESSION } from '@ivy/constants/clinician';
import { RouteUri } from '@ivy/constants/routes';
import { ZOOM_FACILITY, ZOOM_MAX_LOCALITY } from '@ivy/lib/services/maps';
import { buildInternalLink } from '@ivy/lib/util/route';
import useWhitelabel from '@ivy/lib/whitelabel/useWhitelabel';

import FacilityMap from '../FacilityMap';

const FacilitySearch = () => {
	const { profession: rawProfession, slug, page: rawPage } = useParams();
	const profession = SLUG2PROFESSION[rawProfession!];
	const isSearch = slug === 'search';
	const wl = useWhitelabel();

	const pathIsCanonicalRes = () => {
		if (isSearch) {
			return false;
		} else if (rawPage === '1') {
			// Make sure the canonical is /emergency-medicine/physician/raleigh-nc and not /emergency-medicine/physician/raleigh-nc/1
			// for the first page
			return buildInternalLink(RouteUri.SEARCH_INDEX, {
				profession: rawProfession!,
				slug: slug!,
			});
		} else {
			return true;
		}
	};

	const resolveTitle = (loc, prof) => {
		if (prof) {
			return `${prof} jobs${loc ? ` in ${loc}` : ''}`;
		}
		return 'Search facilities';
	};

	const resolveHelmet = (loc, prof) => {
		if (loc && prof) {
			const title = `Emergency ${prof} jobs in ${loc} | ${wl.productName}`;
			const description = `Apply for Emergency ${prof} jobs in ${loc}. Finding your next emergency medicine job has never been easier.`;

			return (
				<Helmet>
					{!!title && <title>{title}</title>}
					{!!description && <meta name='description' content={description} />}
				</Helmet>
			);
		}
		return null;
	};

	// redirect if profession is invalid
	if (!profession) {
		return <Navigate replace to={{ pathname: RouteUri.ROOT }} />;
	}

	return (
		<NavTemplate
			fullscreen
			TopBarProps={{
				sx: {
					borderStyle: 'none',
				},
			}}
			pageNoIndex={isSearch}
			pathIsCanonical={pathIsCanonicalRes()}
			pageTitle='Search Results'
			pageDescription='Apply to over 5,000 emergency departments and 1,500 employer groups. Finding your next emergency medicine job has never been easier.'
		>
			<FacilityMap
				resolveTitle={resolveTitle}
				resolveHelmet={resolveHelmet}
				baseRoute={RouteUri.SEARCH_INDEX}
				pageRoute={RouteUri.SEARCH_PAGE}
				maxViewportZoom={ZOOM_MAX_LOCALITY}
				maxZoom={ZOOM_FACILITY}
				showInstructions
				clustering
				showAlts
				badgeIcon={<RunningToWorkIcon sx={{ fontSize: '1em' }} />}
			/>
		</NavTemplate>
	);
};

export default FacilitySearch;

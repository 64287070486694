import React from 'react';

import { Help as HelpIcon } from '@mui/icons-material';
import {
	Box,
	type SxProps,
	type Theme,
	Tooltip,
	Typography,
} from '@mui/material';

export interface SectionHeaderProps {
	title?: string;
	children?: React.ReactNode;
	noBackground?: boolean;
	tooltipContent?: React.ReactNode;
	disablePadding?: boolean;
	sx?: SxProps<Theme>;
}

const SectionHeader = (props: SectionHeaderProps) => {
	const { title, noBackground, disablePadding, tooltipContent, sx, children } =
		props;

	return (
		<Box component='section' sx={sx}>
			{!!title && (
				<Box display='flex' alignItems='center' mb={{ md: 5, xs: 3 }}>
					<Typography component='h2' variant='h5' fontWeight='bold'>
						{title}
						{tooltipContent && (
							<Tooltip title={tooltipContent} placement='top'>
								<HelpIcon sx={{ fontSize: '0.9rem', ml: 0.5, mb: '-1px' }} />
							</Tooltip>
						)}
					</Typography>
				</Box>
			)}

			<Box
				sx={(theme) => ({
					borderRadius: `${(theme.cardShape || theme.shape).borderRadius}px`,
					boxShadow: noBackground
						? undefined
						: '0px 16px 40px rgba(142, 149, 155, 0.3)',
					p: noBackground || disablePadding ? undefined : 3,
				})}
			>
				{children}
			</Box>
		</Box>
	);
};

export default SectionHeader;

import React, { useState, useRef, useMemo } from 'react';

import { useApolloClient } from '@apollo/client';
import { InfoOutlined } from '@mui/icons-material';
import {
	type Theme,
	ThemeProvider,
	linearProgressClasses,
	useMediaQuery,
	type SxProps,
	Box,
	Typography,
	Stack,
	Stepper,
	Step,
	StepLabel,
	LinearProgress,
	type LinearProgressProps,
} from '@mui/material';
import { captureException } from '@sentry/react';
import {
	type FormikHelpers,
	type FormikProps,
	Form,
	FormikProvider,
} from 'formik-othebi';
import isFunction from 'lodash/isFunction';
import { useSnackbar } from 'notistack';
import { type AnyObject } from 'yup/lib/types';

import { type PopupProps } from '@ivy/components/molecules/Popup';
import {
	type FormStepObj,
	createFormSteps,
	extractInitialValues,
} from '@ivy/lib/forms/formFormatHelpers';
import { useForm } from '@ivy/lib/hooks';
import { combineSx, scrollShadowsSx } from '@ivy/lib/styling/sx';
import { bubbleTheme as bt } from '@ivy/theme/bubble';

import BaseFormPopup from './BaseFormPopup';
import FormButton from './FormButton';
import SideGraphic, { type GraphicContentObjProps } from './SideGraphic';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface StepFormatValues<T extends Record<string, any>, K = void> {
	keys: string[];
	values: FormStepObj<T, K>[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface HeaderProps<T extends Record<string, any>, K = void> {
	steps: StepFormatValues<T, K>;
	activeStep: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Header = <T extends Record<string, any>, K>({
	steps,
	activeStep,
}: HeaderProps<T, K>) => (
	<Stepper
		activeStep={activeStep}
		sx={{
			mb: 7,
			height: '24px',
			maxWidth: '470px',
			display: steps.values.length > 1 ? { sm: 'flex', xs: 'none' } : 'none',
			'& > :first-of-type': { pl: 0 },
		}}
	>
		{steps.values.map((item) => (
			<Step key={item.title}>
				<StepLabel>{item.title}</StepLabel>
			</Step>
		))}
	</Stepper>
);

interface PromptProps {
	open: boolean;
	onClick: () => void;
	onClose?: (event?: React.SyntheticEvent) => void;
	content: GraphicContentObjProps;
}

const Prompt = ({ open, onClick, onClose, content }: PromptProps) => {
	return (
		<ThemeProvider theme={bt}>
			<BaseFormPopup
				onClose={onClose}
				open={open}
				fullWidth
				contentSx={{
					display: 'flex',
					form: { flex: 1 },
					p: 0,
					mt: 0,
				}}
			>
				<SideGraphic onClick={onClick} content={content} />
			</BaseFormPopup>
		</ThemeProvider>
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface ActionProps<T extends Record<string, any>, K = void> {
	onBack?: (event: React.SyntheticEvent) => void;
	steps: StepFormatValues<T, K>;
	formik: FormikProps<T>;
	activeStep: number;
	onClickLater?: (event: React.SyntheticEvent) => void;
	isMobile: boolean;
	sx?: SxProps<Theme>;
	isFinalStep?: boolean;
	prevStep?: boolean;
	strictDisable?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ActionComp = <T extends Record<string, any>, K>({
	activeStep,
	steps,
	formik,
	isMobile,
	onBack,
	onClickLater,
	sx,
	isFinalStep = false,
	prevStep = false,
	strictDisable = true,
}: ActionProps<T, K>) => {
	return (
		<Stack
			direction={{ sm: 'row', xs: 'column' }}
			sx={combineSx(
				{
					width: {
						xs: '100%',
						sm: 'auto',
					},
					justifyContent: 'flex-end',
				},
				sx,
			)}
		>
			<Stack
				direction='row'
				sx={{
					display: prevStep ? 'none' : { sm: 'flex', xs: 'none' },
					justifyContent: 'flex-start',
					width: '100%',
				}}
			>
				<InfoOutlined
					fontSize='small'
					sx={{
						color: 'text.icon',
						verticalAlign: 'middle',
						mr: 2,
					}}
				/>
				<Typography
					variant='body2'
					sx={{
						maxWidth: '330px',
					}}
				>
					You must complete the fields marked with *
				</Typography>
			</Stack>
			{steps.values.length > 1 && isMobile && (
				<LinearProgressWithLabel
					value={activeStep}
					length={steps.keys.length}
					sx={{
						height: '8px',
						borderRadius: '32px',
						[`& .${linearProgressClasses.bar}`]: { borderRadius: '32px' },
					}}
				/>
			)}
			<FormButton
				variant='outlined'
				size='large'
				disabled={formik.isSubmitting}
				onClick={onBack}
				sx={{
					display: prevStep ? 'flex' : 'none',
				}}
			>
				Back
			</FormButton>
			{onClickLater && (
				<FormButton
					variant='outlined'
					size='large'
					disabled={formik.isSubmitting}
					onClick={onClickLater}
				>
					Do this later
				</FormButton>
			)}
			<FormButton
				variant='contained'
				size='large'
				disabled={
					(!formik.dirty && strictDisable && !formik.isValid) ||
					!formik.isValid ||
					formik.isSubmitting
				}
				onClick={formik.submitForm}
			>
				{isFinalStep ? 'Submit' : 'Next'}
			</FormButton>
		</Stack>
	);
};

const LinearProgressWithLabel = ({
	value,
	length,
	...props
}: LinearProgressProps & { value: number; length: number }) => {
	const val = value + 1;
	const progress = val * (100 / length);
	const text = `${val}/${length}`;
	return (
		<Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
			<Box sx={{ mr: 1 }}>
				<Typography variant='body2' color='text.secondary'>
					{text}
				</Typography>
			</Box>
			<Box sx={{ width: '100%' }}>
				<LinearProgress variant='determinate' value={progress} {...props} />
			</Box>
		</Box>
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const formatSteps = <T extends Record<string, any>, K>(
	steps: {
		[key: string]: FormStepObj<T, K>;
	},
	extra?: K,
): StepFormatValues<T, K> => {
	const updatedSteps = Object.keys(steps)
		.filter((key) => {
			const obj = steps[key];
			if (obj && obj.shouldShow && extra) {
				return obj.shouldShow(extra);
			}
			return true;
		})
		.reduce((result: { [key: string]: FormStepObj<T, K> }, key) => {
			result[key] = steps[key];
			return result;
		}, {});

	return {
		keys: Object.keys(updatedSteps),
		values: Object.values(updatedSteps),
	};
};

const SIDE_GRAPHIC_BP = 1050;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface MasterFormPopupProps<T extends Record<string, any>, K = void> {
	onSuccess?: () => void;
	onClose: () => void;
	open: boolean;
	steps: FormStepObj<T, K>[];
	skipPrompt?: boolean;
	formProps?: PopupProps;
	defaultValues?: Partial<T>;
	initialStep?: string;
	extra: K;
	refetchQueries?: string[];
	strictDisable?: boolean;
	displayMobileTitle?: boolean;
	onClickLater?: (event: React.SyntheticEvent) => void;
	formikContext?: AnyObject;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MasterFormPopup = <T extends Record<string, any>, K>({
	onSuccess,
	onClose,
	open,
	initialStep = '',
	skipPrompt = false,
	formProps,
	defaultValues = {},
	steps,
	extra,
	refetchQueries,
	strictDisable = true,
	displayMobileTitle = false,
	onClickLater,
	formikContext,
}: MasterFormPopupProps<T, K>) => {
	const formSteps = createFormSteps(steps);
	const initialValues = extractInitialValues(steps);
	const { enqueueSnackbar } = useSnackbar();
	const [showPrompt, setShowPrompt] = useState(!skipPrompt);
	const formRef = useRef<HTMLFormElement>(null);
	const client = useApolloClient();
	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('sm'),
	);
	const isLtSideGraphicBp = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down(SIDE_GRAPHIC_BP),
	);

	const currSteps = formatSteps<T, K>(formSteps, extra);
	const foundIndex = currSteps.keys.indexOf(initialStep);
	const initialIndex = foundIndex !== -1 ? foundIndex : 0;
	const [currStepKey, setCurrStepKey] = useState(initialIndex);

	const defaultFormValues = useMemo(() => {
		return Object.assign(
			{},
			initialValues,
			Object.entries(defaultValues).reduce(
				(curr, [k, v]) =>
					v !== undefined
						? {
								...curr,
								[k]: v,
						  }
						: curr,
				{},
			),
		);
	}, [defaultValues, initialValues]) as T;

	const stepObj = currSteps.values[currStepKey];
	const schema = stepObj.validate;

	const handleNext = (values: T) => {
		if (stepObj.closeStep?.(values, extra)) {
			onSuccess?.();
		} else if (stepObj.nextStep) {
			setCurrStepKey(currSteps.keys.indexOf(stepObj.nextStep(values, extra)));
		}
	};
	const handleBack = (resetForm, values: T) => {
		if (stepObj.prevStep) {
			setCurrStepKey(currSteps.keys.indexOf(stepObj.prevStep(values, extra)));
			resetForm({ values });
		}
	};
	const handleSuccess = (
		shouldResetForm: boolean,
		values: T,
		actions: FormikHelpers<T>,
		successText = '',
	) => {
		if (successText) enqueueSnackbar(successText, { variant: 'success' });
		handleNext(values);
		actions.setTouched({});
		actions.setSubmitting(false);
		if (shouldResetForm) actions.resetForm?.({ values });
	};
	const handleError = (
		actions: FormikHelpers<T>,
		e: unknown,
		context?: { [key: string]: object },
		errorText = '',
	) => {
		if (errorText) enqueueSnackbar(errorText, { variant: 'error' });
		actions.setSubmitting(false);
		if (e instanceof Error) {
			console.error(e);
			if (!errorText)
				enqueueSnackbar(e.message, {
					variant: 'error',
				});
			formik.setStatus(e.message);
			captureException(e, context);
		}
	};

	const formik = useForm<T>({
		initialValues: defaultFormValues,
		onSubmit: async (values, actions) => {
			if (formRef?.current) formRef.current?.scrollIntoView();
			if (stepObj.submit) {
				await stepObj.submit(
					stepObj.validate || null,
					values,
					actions,
					client,
					(txt) => handleSuccess(true, values, actions, txt),
					(e, context) => handleError(actions, e, context),
					extra,
					refetchQueries,
				);
			} else {
				handleSuccess(false, values, actions);
			}
		},
		context: {
			strict: false,
			...formikContext,
		},
		validationSchema: schema,
	});

	if (showPrompt && isLtSideGraphicBp && stepObj.graphicContent) {
		return (
			<Prompt
				open={open}
				onClose={onClose}
				onClick={() => setShowPrompt(false)}
				content={
					isFunction(stepObj.graphicContent)
						? stepObj.graphicContent(extra)
						: stepObj.graphicContent
				}
			/>
		);
	}

	const isFinalStep = stepObj.finalStep?.(formik.values, extra);

	return (
		<ThemeProvider theme={bt}>
			<FormikProvider value={formik}>
				<BaseFormPopup
					{...formProps}
					open={open}
					onClose={onClose}
					title={
						displayMobileTitle
							? isMobile
								? stepObj.title
								: undefined
							: undefined
					}
					actions={
						isMobile ? (
							<ActionComp
								formik={formik}
								activeStep={currStepKey}
								isMobile={isMobile}
								steps={currSteps}
								onBack={() => handleBack(formik.resetForm, formik.values)}
								prevStep={!!stepObj.prevStep}
								isFinalStep={isFinalStep}
								strictDisable={strictDisable}
								onClickLater={onClickLater}
							/>
						) : undefined
					}
					fullWidth
					contentSx={{
						display: 'flex',
						form: { flex: 1 },
						p: 0,
						mt: 0,
						...scrollShadowsSx,
					}}
				>
					{!!stepObj.graphicContent && (
						<SideGraphic
							sx={(theme) => ({
								display: 'flex',
								maxWidth: {
									xs: '380px',
									xl: '530px',
								},
								[theme.breakpoints.down(SIDE_GRAPHIC_BP)]: {
									display: 'none',
								},
							})}
							content={
								isFunction(stepObj.graphicContent)
									? stepObj.graphicContent(extra)
									: stepObj.graphicContent
							}
						/>
					)}
					<Stack
						sx={{
							flex: 1,
							py: { xl: 10, sm: 5, xs: 3 },
							px: { xl: 15, sm: 5, xs: 3 },
							pb: { xl: 10, sm: 5, xs: 3 },
							maxWidth: '100%',
						}}
					>
						<Header activeStep={currStepKey} steps={currSteps} />
						<Box
							sx={{
								flex: 1,
								height: { sm: 'calc(100% - 104px)', xs: 'auto' },
							}}
						>
							<Box
								component={Form}
								sx={{
									height: '100%',
									display: 'flex',
									flexFlow: 'column nowrap',
								}}
								ref={formRef}
							>
								<Box
									sx={{
										flex: 1,
										overflowY: 'auto',
										mx: { xl: -15, sm: -5, xs: -3 },
										px: { xl: 15, sm: 5, xs: 3 },
										pb: 6,
										mb: 'auto',
										...scrollShadowsSx,
									}}
								>
									<stepObj.component
										formik={formik}
										initialStep={initialStep}
										extra={extra}
									/>
								</Box>
								<ActionComp
									formik={formik}
									activeStep={currStepKey}
									steps={currSteps}
									isMobile={isMobile}
									onBack={() => handleBack(formik.resetForm, formik.values)}
									sx={{
										display: !isMobile ? 'flex' : 'none',
										mt: 5,
									}}
									prevStep={!!stepObj.prevStep}
									isFinalStep={isFinalStep}
									strictDisable={strictDisable}
									onClickLater={onClickLater}
								/>
							</Box>
						</Box>
					</Stack>
				</BaseFormPopup>
			</FormikProvider>
		</ThemeProvider>
	);
};

export default MasterFormPopup;
export { type MasterFormPopupProps };

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';

import { EdVolume } from '@ivy/constants/facility';

import { VOLUME_OPTIONS } from './VolumeFilterOptions';

export const prepareSimpleFilter =
	<T>(field: string, altMatch?: string) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(val: T): { [key: string]: any } => {
		const match = altMatch || '_eq';
		return val === null ? {} : set({}, field, { [match]: val });
	};

// When partialMatch is true we will use partial matching. Strict matching is the default in all other cases.
const orOperation = (
	field: string,
	arr: (string | number | null)[],
	partialMatch?: boolean,
) => {
	let value = {};

	if (partialMatch) {
		value = {
			_or: arr.map((el) => set({}, field, { _contains: [el] })),
		};
	} else {
		value = set({}, field, { _in: arr });
	}

	return value;
};

const formatApiStructure = (
	field: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	selected: (string | number | null)[],
	partialMatch?: boolean,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): { [k: string]: any } => {
	const nullIdx = selected.indexOf(null);

	if (nullIdx >= 0) {
		if (selected.length >= 2) {
			return {
				_or: [
					{
						// Handle case where nested object DNE, or if it does exist, final value is null
						_not: set({}, field, { _is_null: false }),
					},
					orOperation(
						field,
						selected.filter((el) => el !== null),
						partialMatch,
					),
				],
			};
		}
		return {
			_not: set({}, field, {
				// Handle case where nested object DNE, or if it does exist, final value is null
				_is_null: false,
			}),
		};
	}

	return orOperation(field, selected, partialMatch);
};

export const prepareFilters =
	<T>(
		field: string,
		options: { label: string; value: string }[],
		parser?: (value: string) => string | number,
		partialMatch?: boolean,
	) =>
	(value: T) => {
		if (!Array.isArray(value) || value.length === 0) return {};

		const selected = value.reduce(
			(tot, k) => {
				if (k === 'none') {
					return [...tot, null];
				}
				return [...tot, parser ? parser(k) : k];
			},
			[] as (string | number | null)[],
		);

		if (!selected.length || selected.length === options.length) {
			return {};
		} else {
			return formatApiStructure(field, selected, partialMatch);
		}
	};

export const prepareEDVisitsFilters = <T>(value: T) => {
	if (
		!Array.isArray(value) ||
		value.length === 0 ||
		value.length === VOLUME_OPTIONS.length
	) {
		return {};
	}

	const baseQuery = {
		// This clause requires the CMS facility exist
		cms_facility: {
			metrics: {
				measure_id: {
					_eq: 'EDV',
				},
				score: {
					_in: value,
				},
			},
		},
	};

	if (value.includes(EdVolume.LOW)) {
		// EdVolume.LOW should also include those which have an unknown volume
		return {
			_or: [
				{
					// This clause finds the unknown volumes
					// DeMorgan's law
					// CMS facility DNE || null EDV
					// <=>
					// ~(CMS facility exists && non-null EDV)
					_not: {
						cms_facility: {
							metrics: {
								measure_id: {
									_eq: 'EDV',
								},
								score: {
									_is_null: false,
								},
							},
						},
					},
				},
				baseQuery,
			],
		};
	}
	return baseQuery;
};

export const prepareProgramEDVisitsFilters = <T>(value: T) => {
	if (
		!Array.isArray(value) ||
		value.length === 0 ||
		value.length === VOLUME_OPTIONS.length
	) {
		return {};
	}

	const facilityFilter = prepareEDVisitsFilters(value);

	return {
		latest_survey: {
			survey: {
				training_survey: {
					training_sites: {
						_and: [{ primary: { _eq: true } }, { facility: facilityFilter }],
					},
				},
			},
		},
	};
};

interface optionsObject {
	label: string;
	value: string;
}

export const prepareBoundsFilters =
	<T>(
		field: string,
		options: optionsObject[],
		parser?: (value: string) => string | number,
	) =>
	(value: T) => {
		const defaultBounds = [options[0].value, options[options.length - 1].value];
		if (
			!Array.isArray(value) ||
			value.length === 0 ||
			isEqual(value, defaultBounds)
		)
			return {};

		let query;
		const selected = value.reduce(
			(tot, k) => {
				return [...tot, parser ? parser(k) : k];
			},
			[] as (string | number)[],
		);

		if (selected.length === 1) {
			// If only one number is provided
			const condition = set({}, field, { _gte: selected[0] });
			if (selected[0] === 0) {
				// Include condition for unknown values if the number is 0
				const isNullCondition = set({}, field, { _is_null: true });
				query = { _or: [isNullCondition, condition] };
			} else {
				query = condition;
			}
		} else if (selected.length === 2) {
			// If two numbers are provided, use them as lower and upper bounds
			const lowerBoundCondition = set({}, field, { _gte: selected[0] });
			const upperBoundCondition = set({}, field, { _lte: selected[1] });

			query = {
				_or: [{ _and: [lowerBoundCondition, upperBoundCondition] }],
			};

			if (selected[0] === 0) {
				// Include condition for unknown values if the lower bound is 0
				const isNullCondition = set({}, field, { _is_null: true });
				query = {
					_or: [isNullCondition, ...query._or],
				};
			}
		} else {
			// Handle invalid 'selected' array length
			throw new Error("Invalid number of elements in 'selected' array.");
		}

		// The query is already constructed with the appropriate field structure
		return query;
	};

export const prepareAvalabilityFilters = <T>(value: T) => {
	if (!Array.isArray(value) || value.length === 0 || isEmpty(value)) {
		return {};
	}

	const result: { latest_survey: { survey: { [key: string]: object } } } = {
		latest_survey: {
			survey: {},
		},
	};

	value.forEach((spot, index) => {
		const key = `spot_availability_${index + 1}`;
		result.latest_survey.survey[key] =
			typeof spot === 'boolean' ? { _eq: spot } : { _is_null: true };
	});

	return result;
};

import { useQuery, useReactiveVar } from '@apollo/client';

import { HasuraRole } from '@ivy/constants/headers';
import {
	isAuthenticatedVar,
	currentAccountVar,
	type CurrAcc,
} from '@ivy/gql/reactives';

export const useAnonymousQuery = (query, options) => {
	return useQuery(query, {
		...options,
		context: {
			...options.context,
			headers: {
				'X-Hasura-Role': HasuraRole.ANON,
			},
		},
	});
};

export const useAccountType = () => {
	const currAcc = useReactiveVar(currentAccountVar);

	return currAcc?.type || null;
};

export const useAuthInfoLoaded = () => {
	const isAuth = useReactiveVar(isAuthenticatedVar);
	const currAcc = useReactiveVar(currentAccountVar);

	return isAuth && !!currAcc;
};

export const useCurrentAccount = (): CurrAcc | null => {
	return useReactiveVar(currentAccountVar);
};

export const useCurrentOrgId = () => {
	const currAcc = useCurrentAccount();
	return currAcc?.orgUser?.org?.id || null;
};

// TODO: add verified badges next to employer names
import React, { useState } from 'react';

import {
	Box,
	Typography,
	IconButton,
	type SxProps,
	type Theme,
	useMediaQuery,
} from '@mui/material';

import { AccountAvatar } from '@ivy/components/atoms/PlaceholderAvatar';
import RouteLink from '@ivy/components/atoms/RouteLink';
import { PencilIcon } from '@ivy/components/icons';
import { EmployerIcon, OwnershipIcon } from '@ivy/components/icons';
import LabeledTypography from '@ivy/components/molecules/LabeledTypography';
import ApplyToJobButton, {
	type ApplyToJobButtonProps,
} from '@ivy/components/organisms/ApplyToJobButton';
import EditRecruiterPopup from '@ivy/components/organisms/EditRecruiterPopup';
import { OWNERSHIP_DESCRIPTION } from '@ivy/constants/organization';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql, getFragmentData, type FragmentType } from '@ivy/gql/types';
import { combineSx } from '@ivy/lib/styling/sx';

const EmployerInfo_ContractFDoc = gql(/* GraphQL */ `
	fragment EmployerInfo_Contract on facility_contract {
		id
		active
		isOwner: is_contract_owner
		org {
			id
			name
			ownershipStructure: ownership_structure
		}
		recruiter {
			id
			account {
				id
				pi: personal_info {
					id
					firstName: first_name
					lastName: last_name
					fullName: full_name
				}
				...AccountAvatar_Account
			}
		}
		...EditRecruiterPopup_Contract
		...ApplyToJobButton_Contract
	}
`);

export interface EmployerInfoProps {
	contract: FragmentType<typeof EmployerInfo_ContractFDoc>;
	sx?: SxProps<Theme>;
	headerSx?: SxProps<Theme>;
	cardSx?: SxProps<Theme>;
	onClickApply: ApplyToJobButtonProps['onClickApply'];
}

const EmployerInfo = ({
	contract: rawContract,
	onClickApply,
	sx,
	headerSx,
	cardSx,
}: EmployerInfoProps) => {
	const contract = getFragmentData(EmployerInfo_ContractFDoc, rawContract);
	const currAcc = useCurrentAccount();
	const [showEditRecruiter, setShowEditRecruiter] = useState(false);
	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('sm'),
	);
	const { org, recruiter, isOwner } = contract;

	return (
		<>
			{isOwner && showEditRecruiter && (
				<EditRecruiterPopup
					contracts={[contract]}
					open
					onClose={() => setShowEditRecruiter(false)}
				/>
			)}

			<Box component='section' sx={sx}>
				<Box
					sx={combineSx(
						{
							display: 'flex',
							alignItems: 'center',
							mb: 2,
						},
						headerSx,
					)}
				>
					<EmployerIcon
						sx={{
							width: 'auto',
							height: 'auto',
							mr: 2,
						}}
					/>
					{
						<Typography component='h2' variant='h5'>
							<RouteLink
								to={RouteLink.routes.ORG_SHOW.replace(':orgId', org.id)}
								underline='hover'
								color='inherit'
							>
								{org.name}
							</RouteLink>
						</Typography>
					}
					{isOwner && (
						<IconButton
							sx={{
								ml: 1,
							}}
							onClick={() => setShowEditRecruiter(true)}
						>
							<PencilIcon />
						</IconButton>
					)}
				</Box>

				<Box
					sx={combineSx(
						{
							boxShadow: '0px 16px 40px rgba(142, 149, 155, 0.1)',
							borderRadius: (theme) => `${theme.shape.borderRadius}px`,
						},
						cardSx,
					)}
				>
					<Box
						sx={{
							p: 3,
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<LabeledTypography
							label='Employer Ownership Structure'
							text={OWNERSHIP_DESCRIPTION[org.ownershipStructure] || 'N/A'}
							sx={{
								flex: '1 1',
							}}
						/>
						<OwnershipIcon
							sx={{
								ml: 1,
								fontSize: '40px',
								flex: '0 0 40px',
							}}
						/>
					</Box>
					<Box
						sx={{
							p: 3,
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							borderTop: (theme) => `1px solid ${theme.palette.divider}`,
						}}
					>
						<LabeledTypography
							label='Recruiter'
							text={recruiter!.account!.pi!.fullName}
							icon={<AccountAvatar account={recruiter.account} />}
						/>

						{(!currAcc || currAcc.isClinician) && contract.active && (
							<ApplyToJobButton
								btnId='pg-facility--btn-connect__employer'
								contracts={[contract]}
								onClickApply={onClickApply}
								size={isMobile ? 'small' : undefined}
								variant='short'
							/>
						)}
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default EmployerInfo;

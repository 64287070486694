import React from 'react';

import { ErrorOutline } from '@mui/icons-material';
import {
	Box,
	Card,
	CardActionArea,
	CardContent,
	CardMedia,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material';
import { format as formatDate } from 'date-fns';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { useRedirect } from '@ivy/components/providers/RedirectProvider';
import { FACILITY_PLACEHOLDER_IMAGE } from '@ivy/constants/facility';
import { gql, getFragmentData, type FragmentType } from '@ivy/gql/types';
import { buildInternalLink } from '@ivy/lib/util/route';

const JobApplicationListItem_JobApplicationFDoc = gql(/* GraphQL */ `
	fragment JobApplicationListItem_JobApplication on job_application {
		id
		createdAt: created_at
		createdAtDate @client
		contract {
			id
			active
			facility {
				id
				name
				slug
				picture {
					id
					publicUrl: public_url
				}
				city
				state
			}
			org {
				id
				name
			}
		}
	}
`);

export interface JobApplicationListItemProps {
	jobApp: FragmentType<typeof JobApplicationListItem_JobApplicationFDoc>;
}

const JobApplicationListItem = ({
	jobApp: rawJobApp,
}: JobApplicationListItemProps) => {
	const jobApp = getFragmentData(
		JobApplicationListItem_JobApplicationFDoc,
		rawJobApp,
	);
	const redirect = useRedirect();
	const theme = useTheme();
	const {
		contract: { active: contractActive, facility, org },
		createdAtDate,
	} = jobApp;

	const handleClickCard = () => {
		redirect(
			buildInternalLink(RouteLink.routes.FACILITY_SHOW, {
				facilityId: [facility.id, facility.slug],
			}),
			{
				state: {
					backNav: {
						target: 'applications',
					},
				},
			},
		);
	};

	return (
		// Show overflow for badge icon to appear properly on mobile
		<Card elevation={0} onClick={handleClickCard} sx={{ overflow: 'visible' }}>
			<CardActionArea
				component='div'
				sx={{
					display: 'flex',
					flexDirection: {
						xs: 'column',
						sm: 'row',
					},
					borderRadius: `${theme.shape.borderRadius}px`,
				}}
			>
				<CardMedia
					image={facility.picture?.publicUrl || FACILITY_PLACEHOLDER_IMAGE}
					sx={{
						// This will blur the edge a bit. Solution is to wrap in a box to keep the sharp border, but it
						// messes with the sm breakpoint since the height goes to 0
						// filter: !facility.picture?.publicUrl && 'blur(1px)',
						height: '100px',
						width: {
							xs: '100%',
							sm: '150px',
						},
						borderRadius: `${theme.shape.borderRadius}px`,
					}}
				/>
				<CardContent
					sx={{
						// flex: 1 0 auto doesn't work, also need the shrink
						flex: '1',
						py: 0.5,
						maxWidth: '100%',
						// Repeat overflow here and above to get ellipsis to work
						overflow: 'hidden',
						display: 'flex',
						flexDirection: 'column',
						width: {
							xs: '100%',
							sm: 'initial',
						},
						px: {
							xs: 1,
							sm: 2,
						},
						height: '100px',
					}}
				>
					<Typography variant='caption' noWrap>
						{facility.city}, {facility.state}
					</Typography>
					<Typography variant='body1' fontWeight='bold' noWrap>
						{facility.name}
					</Typography>
					<Box display='flex' alignItems='start' overflow='hidden'>
						<Typography variant='body1' noWrap>
							{org.name}
						</Typography>
						{!contractActive && (
							<Tooltip title={'Employer no longer staffs this facility.'}>
								<ErrorOutline
									fontSize='small'
									sx={{
										color: 'warning.main',
										verticalAlign: 'middle',
										ml: 1,
									}}
								/>
							</Tooltip>
						)}
					</Box>
					<Typography variant='caption' component='p' mt='auto'>
						{`Applied ${formatDate(createdAtDate, 'MMM d, yyyy')}`}
					</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	);
};

export default JobApplicationListItem;

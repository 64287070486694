import React from 'react';

import { Help as HelpIcon } from '@mui/icons-material';
import {
	Box,
	Button,
	type ButtonProps,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';

import Popup, { type PopupProps } from '@ivy/components/molecules/Popup';

import {
	type FilterDataStructure,
	type EntityFilterValues,
} from './useEntityFilters';

interface FiltersPopupProps
	extends Omit<PopupProps, 'onClose' | 'onChange' | 'onReset' | 'onSubmit'> {
	value: EntityFilterValues;
	onChange: (nv: EntityFilterValues) => void;
	disabled?: boolean;
	onClose: () => void;
	onSubmit: ButtonProps['onClick'];
	onReset: ButtonProps['onReset'];
	filters: FilterDataStructure[];
}

const FiltersPopup = ({
	value,
	onChange,
	disabled,
	onClose,
	onSubmit,
	onReset,
	filters,
	...props
}: FiltersPopupProps) => {
	const renderFilters = (fltrs: FilterDataStructure[]) => {
		const handleChange =
			(namespace: string) =>
			(
				ev: React.SyntheticEvent,
				nv: string | boolean | (string | boolean | null | undefined)[] | null,
			) => {
				onChange({
					...value,
					[namespace]: nv,
				});
			};

		return fltrs.map((filter) => (
			<Box key={filter.key}>
				<Typography variant='h6' component='h2' mb={2}>
					{filter.title}
					{filter.tooltipContent && (
						<Tooltip title={filter.tooltipContent} placement='top'>
							<HelpIcon sx={{ fontSize: '0.9rem', ml: 0.5, mb: '-1px' }} />
						</Tooltip>
					)}
				</Typography>
				<filter.component
					options={filter.options || []}
					value={value[filter.key]}
					onChange={handleChange(filter.key)}
				/>
			</Box>
		));
	};

	return (
		<Popup
			title='Filters'
			actions={
				<>
					<Button disabled={disabled} onClick={onClose} sx={{ mr: 'auto' }}>
						Cancel
					</Button>
					<Button
						variant='outlined'
						disabled={disabled}
						onClick={onReset}
						sx={{ mr: 1 }}
					>
						Reset
					</Button>
					<Button variant='contained' disabled={disabled} onClick={onSubmit}>
						Apply
					</Button>
				</>
			}
			onClose={onClose}
			{...props}
		>
			<Stack spacing={6}>{renderFilters(filters)}</Stack>
		</Popup>
	);
};

export default FiltersPopup;

import { useEffect, useState } from 'react';

import { captureException } from '@sentry/react';
import GoogleMapReact from 'google-map-react';

import config from '@ivy/config';
import { AppError } from '@ivy/lib/helpers/error';

let _placesService: google.maps.places.PlacesService | null = null;
let _autocompleteService: google.maps.places.AutocompleteService | null = null;
let _geocodeService: google.maps.Geocoder | null = null;

export interface Coordinates {
	lat: number;
	lng: number;
}

export interface Bounds {
	xMin: number;
	xMax: number;
	yMin: number;
	yMax: number;
}

export type LocationType =
	| 'navigator'
	| 'precise'
	| 'place'
	| 'facility'
	| 'org'
	| 'residency'
	| 'clerkship'
	| 'fellowship';

export interface AbstractLocation {
	id: string;
	type: LocationType;
	label: string;
}

export interface UrlLocation extends AbstractLocation {
	url: string;
}

export interface NavigatorLocation extends AbstractLocation {
	type: 'navigator';
}

export interface PreciseLocation extends AbstractLocation {
	type: 'precise';
	center: Coordinates;
}

export interface ZoomPreciseLocation extends PreciseLocation {
	zoom: number;
}

export interface ViewportPreciseLocation extends PreciseLocation {
	viewport: Bounds;
}

export interface IncompletePlaceLocation extends AbstractLocation {
	placeId: string;
}

export interface CompletePlaceLocation extends AbstractLocation {
	placeId: string;
	center: Coordinates;
	viewport: Bounds;
	slug?: string;
}

export interface FacilityLocation extends UrlLocation {
	type: 'facility';
}

export interface OrgLocation extends UrlLocation {
	type: 'org';
}

export interface ResidencyLocation extends UrlLocation {
	type: 'residency';
}

export interface ClerkshipLocation extends UrlLocation {
	type: 'clerkship';
}

export interface FellowshipLocation extends UrlLocation {
	type: 'fellowship';
}

export type IncompleteLocation = NavigatorLocation | IncompletePlaceLocation;
export type CompleteLocation =
	| ViewportPreciseLocation
	| ZoomPreciseLocation
	| CompletePlaceLocation
	| FacilityLocation
	| OrgLocation
	| ResidencyLocation
	| ClerkshipLocation
	| FellowshipLocation;

export interface MapDimensions {
	width: number;
	height: number;
}

export interface LatLng {
	lat: number;
	lng: number;
}

const autocompleteService =
	(): google.maps.places.AutocompleteService | null => {
		if (_autocompleteService) {
			return _autocompleteService;
		}
		if (!window.google?.maps?.places?.AutocompleteService) {
			return null;
		}
		_autocompleteService = new window.google.maps.places.AutocompleteService();
		return _autocompleteService;
	};

const placesService = (): google.maps.places.PlacesService | null => {
	if (_placesService) {
		return _placesService;
	}
	if (!window.google?.maps?.places?.PlacesService) {
		return null;
	}
	_placesService = new window.google.maps.places.PlacesService(
		document.createElement('div'),
	);
	return _placesService;
};

const geocodeService = (): google.maps.Geocoder | null => {
	if (_geocodeService) {
		return _geocodeService;
	}
	if (!window.google?.maps?.Geocoder) {
		return null;
	}
	_geocodeService = new window.google.maps.Geocoder();
	return _geocodeService;
};

class GMapsError extends AppError {
	statusCode?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	extra?: Record<string, any>;

	constructor(
		message?: string,
		statusCode?: string,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		extra?: Record<string, any>,
	) {
		super(message);
		this.statusCode = statusCode;
		this.extra = extra;
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncFnWithArgs<T extends any[], R> = (...args: T) => Promise<R>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const retryGmapsFn = <T extends any[], R>(
	fn: AsyncFnWithArgs<T, R>,
	initialDelay = 250,
	maxAttempts = 4,
): AsyncFnWithArgs<T, R> => {
	return async (...args: T): Promise<R> => {
		// With defaults, max waiting time will be 250 + 2*250 + 4*250 = 0.75s, in addition to however long the API requests
		// take.
		let attempts = 1;
		while (true) {
			try {
				return await fn(...args);
			} catch (err) {
				if (
					attempts < maxAttempts &&
					err instanceof GMapsError &&
					err.statusCode &&
					(
						[
							google.maps.GeocoderStatus.UNKNOWN_ERROR,
							google.maps.places.PlacesServiceStatus.UNKNOWN_ERROR,
						] as string[]
					).includes(err.statusCode)
				) {
					// Wait until the next attempt
					const delay = 2 ** (attempts - 1) * initialDelay;
					await new Promise((resolve) => setTimeout(resolve, delay));
					attempts++;
				} else {
					console.error(err);
					console.log(
						err instanceof GMapsError
							? {
									extra: {
										status: err.statusCode,
										...err.extra,
									},
							  }
							: undefined,
					);
					captureException(
						err,
						err instanceof GMapsError
							? {
									extra: {
										status: err.statusCode,
										...err.extra,
									},
							  }
							: undefined,
					);
					throw err;
				}
			}
		}
	};
};

export const getPlaceSuggestions = retryGmapsFn(
	(
		search: google.maps.places.AutocompletionRequest,
	): Promise<google.maps.places.AutocompletePrediction[]> => {
		return new Promise((resolve, reject) => {
			const service = autocompleteService();
			if (!service) {
				return reject(new Error('Google Maps service has not loaded yet.'));
			}
			service.getPlacePredictions(search, (predictions, status) => {
				if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
					if (
						status ===
						window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS
					) {
						return resolve([]);
					}
					return reject(
						new GMapsError('Search failed.', status, {
							search,
							predictions,
						}),
					);
				}
				if (!predictions) {
					// Cast falsy to empty array
					return resolve([]);
				}
				return resolve(predictions);
			});
		});
	},
);

export const getPlaceDetails = retryGmapsFn(
	async (
		request: google.maps.places.PlaceDetailsRequest,
	): Promise<google.maps.places.PlaceResult | null> => {
		return new Promise((resolve, reject) => {
			const service = placesService();
			if (!service) {
				return reject(new Error('Google Maps service has not loaded yet.'));
			}
			service.getDetails(request, (result, status) => {
				if (
					![
						window.google.maps.places.PlacesServiceStatus.OK,
						window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS,
					].includes(status)
				) {
					return reject(
						new GMapsError('Search failed.', status, {
							request,
							result,
						}),
					);
				}
				return resolve(result);
			});
		});
	},
);

export const geocode = retryGmapsFn(
	async (
		request: google.maps.GeocoderRequest,
	): Promise<google.maps.GeocoderResult | null> => {
		// https://developers.google.com/maps/documentation/javascript/geocoding
		// Request can be { address, location, placeId, bounds, componentRestrictions, region }
		return new Promise((resolve, reject) => {
			const service = geocodeService();
			if (!service) {
				return reject(new Error('Google Maps service has not loaded yet.'));
			}
			service.geocode(request, (results, status) => {
				if (
					![
						window.google.maps.GeocoderStatus.OK,
						window.google.maps.GeocoderStatus.ZERO_RESULTS,
					].includes(status)
				) {
					return reject(
						new GMapsError('Search failed.', status, {
							request,
							results,
						}),
					);
				}
				return resolve(results?.[0] ?? null);
			});
		});
	},
);

export const searchPlace = retryGmapsFn(
	async (
		request: google.maps.places.TextSearchRequest,
	): Promise<google.maps.places.PlaceResult | null> => {
		return new Promise((resolve, reject) => {
			const service = placesService();
			if (!service) {
				return reject(new Error('Google Maps service has not loaded yet.'));
			}
			service.textSearch(request, (results, status) => {
				if (
					![
						window.google.maps.places.PlacesServiceStatus.OK,
						window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS,
					].includes(status)
				) {
					return reject(
						new GMapsError('Search failed.', status, {
							request,
							results,
						}),
					);
				}
				return resolve(results?.[0] ?? null);
			});
		});
	},
);

export const searchText = retryGmapsFn(
	async (
		request: google.maps.places.TextSearchRequest,
	): Promise<google.maps.places.PlaceResult | null> => {
		return new Promise((resolve, reject) => {
			const service = placesService();
			if (!service) {
				return reject(new Error('Google Maps service has not loaded yet.'));
			}
			service.textSearch(request, (results, status) => {
				if (
					![
						window.google.maps.places.PlacesServiceStatus.OK,
						window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS,
					].includes(status)
				) {
					return reject(
						new GMapsError('Search failed.', status, {
							request,
							results,
						}),
					);
				}
				if (!results?.length) {
					return resolve(null);
				}
				return resolve(results[0]);
			});
		});
	},
);

export const getBoundsZoomLevel = (
	bounds: Bounds,
	mapDim: MapDimensions,
	zoomMax = 21,
) => {
	const WORLD_DIM: MapDimensions = { height: 256, width: 256 };

	const latRad = (lat: number) => {
		const sin = Math.sin((lat * Math.PI) / 180);
		const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
		return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
	};

	const zoom = (mapPx: number, worldPx: number, fraction: number) => {
		return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
	};

	const latFraction = (latRad(bounds.yMax) - latRad(bounds.yMin)) / Math.PI;

	const lngDiff = bounds.xMax - bounds.xMin;
	const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

	const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
	const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

	return Math.min(latZoom, lngZoom, zoomMax);
};

export const canGetCurrentLocation = () => {
	return !!navigator.geolocation;
};

export const getCurrentLocation = (): Promise<GeolocationPosition> => {
	return new Promise((resolve, reject) => {
		navigator.geolocation.getCurrentPosition(
			(position) => {
				return resolve(position);
			},
			(error) => {
				return reject(error);
			},
		);
	});
};

export const reverseGeocode = (latLng: LatLng) => {
	return geocode({ location: latLng });
};

export const getCurrentPlace = async () => {
	const position = await getCurrentLocation();
	return reverseGeocode({
		lat: position.coords.latitude,
		lng: position.coords.longitude,
	});
};

export const BOOTSTRAP_URL_KEYS = {
	key: config.gmapsApiKey,
	libraries: ['places'],
};

export const ZOOM_FACILITY = 17;
export const ZOOM_CURRENT_LOCATION = 10;
export const ZOOM_MAX_LOCALITY = 10;

export const getCappedZoom = (
	newZoom: number,
	minZoom?: number,
	maxZoom?: number,
) =>
	Math.max(
		minZoom ?? Number.NEGATIVE_INFINITY,
		Math.min(newZoom, maxZoom ?? Number.POSITIVE_INFINITY),
	);

export const useGmaps = (disabled = false) => {
	const [ready, setReady] = useState(false);

	useEffect(() => {
		if (disabled) {
			return;
		}
		const loadGmaps = async () => {
			// @ts-ignore
			await GoogleMapReact.googleMapLoader(BOOTSTRAP_URL_KEYS);
			setReady(true);
		};
		loadGmaps();
	}, [disabled, setReady]);

	return disabled || ready;
};

export const calculateRecommendedViewport = (bounds: Bounds): Bounds => {
	// Add 1/10 padding on all sides
	const xDelta = bounds.xMax - bounds.xMin;
	const yDelta = bounds.yMax - bounds.yMin;
	return {
		xMin: bounds.xMin - xDelta / 10,
		xMax: bounds.xMax + xDelta / 10,
		yMin: bounds.yMin - yDelta / 10,
		yMax: bounds.yMax + yDelta / 10,
	};
};

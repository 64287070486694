import React, { useCallback, useEffect } from 'react';

import { gql, useQuery } from '@apollo/client';
import { useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';

import DataLoader from '@ivy/components/molecules/DataLoader';
import { useAuthPopup } from '@ivy/components/providers/AuthPopupProvider';
import NavTemplate from '@ivy/components/templates/NavTemplate';
import { useCurrentAccount } from '@ivy/gql/hooks';

import Hero from './Hero';
import HowItWorks from './HowItWorks';
import Testimonials from './Testimonials';
import ValueProp1 from './ValueProp1';
import ValueProp2 from './ValueProp2';

const MIN_HEIGHT_DESKTOP = 720;
const MIN_HEIGHT_MOBILE = 650;

const ForEmployers_FacilityCountQDoc = gql(/* GraphQL */ `
	query ForEmployers_FacilityCount {
		fac: facility_aggregate(where: { contracts: { active: { _eq: true } } }) {
			aggregate {
				count
			}
		}
		org: organization_aggregate(
			where: { contracts: { active: { _eq: true } } }
		) {
			aggregate {
				count
			}
		}
	}
`);

const ForEmployers = () => {
	const theme = useTheme();
	const [searchParams, setSearchParams] = useSearchParams();
	const { openSignupPopup } = useAuthPopup();
	// http://localhost:3000/employers?showSignup=true&inviteEmail=matt%2Bjo%40ivyclinicians.io&inviteOrgId=f79976f4-0000-0000-0000-000000000000
	// http://localhost:3000/employers?showSignup=true&inviteEmail=matt%2Bspamrecruiter%40ivyclinicians.io&inviteOrgId=f79976f4-0000-0000-0000-000000000002

	const currAcc = useCurrentAccount();
	const { enqueueSnackbar } = useSnackbar();
	const { data, loading, error } = useQuery(ForEmployers_FacilityCountQDoc);

	const handleClickCta = useCallback(() => {
		if (currAcc) {
			enqueueSnackbar("You're already signed up!", {
				variant: 'info',
			});
		} else {
			openSignupPopup();
		}
	}, [currAcc, openSignupPopup, enqueueSnackbar]);

	useEffect(() => {
		if (currAcc || !searchParams.has('showSignup')) {
			return;
		}
		openSignupPopup();
		searchParams.delete('showSignup');
		setSearchParams(searchParams, {
			replace: true,
		});
	}, [currAcc, searchParams, setSearchParams, openSignupPopup]);

	return (
		<NavTemplate
			backgroundColor={theme.palette.light4.main}
			fullscreen
			TopBarProps={{
				transparentUntilScroll: true,
				sx: {
					borderStyle: 'none',
				},
			}}
			pageTitle='For Employers'
			pageDescription='Ivy Clinicians is the emergency medicine job board that connects you with physicians, nurse practitioners, and physician assistants interested in working with your practice.'
			showSupportLauncher
			showFooter
			pathIsCanonical
		>
			<DataLoader
				variant='logo'
				fullscreen
				data={data}
				loading={loading}
				error={error}
			>
				{() => (
					<>
						<Hero
							sx={{
								height: {
									xs: 'calc(100vh - 64px)',
									mobileMenu: 'calc(100vh - 104px)',
								},
								minHeight: {
									xs: MIN_HEIGHT_MOBILE - 64,
									mobileMenu: MIN_HEIGHT_DESKTOP - 64,
								},
							}}
							onClickCta={handleClickCta}
						/>
						<ValueProp1
							sx={{
								py: 15,
							}}
						/>
						<ValueProp2
							sx={{
								pb: {
									xs: 15,
									md: 5,
								},
							}}
						/>
						<HowItWorks
							facCount={data?.fac.aggregate?.count || 0}
							orgCount={data?.org.aggregate?.count || 0}
							sx={{
								mb: 15,
							}}
						/>
						<Testimonials
							sx={{
								mb: 15,
							}}
						/>
					</>
				)}
			</DataLoader>
		</NavTemplate>
	);
};

export default ForEmployers;

import React from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { filterValidData } from '@ivy/components/molecules/FormPopup';
import { type GraphicContentObjProps } from '@ivy/components/molecules/FormPopup/SideGraphic';
import MessageAndCvStep, {
	validation as messageAndCvValidation,
	initialValues as messageAndCvInitialValue,
	type MessageAndCvStepValues,
} from '@ivy/components/organisms/ApplyToJobPopup/MessageAndCvStep/MessageAndCvStep';
import { FACILITY_PLACEHOLDER_IMAGE } from '@ivy/constants/facility';
import { gql } from '@ivy/gql/types';
import {
	type ApplyToJobPopup_ContractFragment,
	type ApplyToJobPopup_PostingFragment,
} from '@ivy/gql/types/graphql';
import { type FileUpload_FileFragment } from '@ivy/gql/types/graphql';
import { oxfordJoin } from '@ivy/lib/formatting/string';
import {
	type FormStepObj,
	type SubmitFunction,
} from '@ivy/lib/forms/formFormatHelpers';
import { getImageKitUrl } from '@ivy/lib/services/imageKit';

type ApplyToJobValues = MessageAndCvStepValues;

type ApplyToJobExtra = {
	prevIntro?: string;
	cv?: FileUpload_FileFragment | null;
	multiFac: boolean;
	multiRec: boolean;
	contracts: readonly ApplyToJobPopup_ContractFragment[];
	posting?: ApplyToJobPopup_PostingFragment | null;
	clinicianId: string;
};

const ApplyToJobPopup_ApplyMDoc = gql(/* GraphQL */ `
	mutation ApplyToJobPopup_Apply(
		$contractIds: [String!]!
		$postingIds: [String!]
		$intro: String
	) {
		apply_to_jobs(
			input: {
				contract_ids: $contractIds
				posting_ids: $postingIds
				intro: $intro
			}
		) {
			success
		}
	}
`);

const ApplyToJobPopup_UploadCvMDoc = gql(/* GraphQL */ `
	mutation ApplyToJobPopup_UploadCv($clinicianId: uuid!, $cvId: uuid!) {
		update_clinician_by_pk(
			pk_columns: { id: $clinicianId }
			_set: { cv_id: $cvId }
		) {
			id
			cv {
				id
				...FileUpload_File
			}
		}
	}
`);

const submit: SubmitFunction<ApplyToJobValues, ApplyToJobExtra> = async (
	validation,
	values,
	actions,
	client,
	onSuccess,
	onError,
	params,
	refetchQueries,
) => {
	const validData = validation ? filterValidData(values, validation) : values;

	// We have two different try/catch statements because the error handling is different on each
	// One has errors from Hasura (redact), the other has errors from the Internal API (don't redact)
	try {
		// If the user removes their CV, we do not save that change
		if (validData.cv && validData.cv.id !== params.cv?.id) {
			await client.mutate({
				mutation: ApplyToJobPopup_UploadCvMDoc,
				variables: {
					clinicianId: params.clinicianId,
					cvId: validData.cv.id,
				},
			});
		}
	} catch (e: unknown) {
		actions?.setSubmitting(false);
		onError(e, { extra: values }, 'An error occurred, please try again.');
		return;
	}
	try {
		await client.mutate({
			mutation: ApplyToJobPopup_ApplyMDoc,
			variables: {
				contractIds: params.contracts.map((el) => el.id),
				postingIds: params.posting ? [params.posting.id] : null,
				intro: validData.message,
			},
			refetchQueries,
			awaitRefetchQueries: true,
		});
		actions?.setSubmitting(false);
		onSuccess(
			`Great work! Your job ${
				params.contracts.length > 1 ? 'applications have' : 'application has'
			} been submitted.`,
		);
	} catch (e) {
		actions?.setSubmitting(false);
		onError(
			e,
			{ extra: values },
			e instanceof Error ? e.message : 'An error occurred, please try again.',
		);
	}
};

const mainGraphic = ({
	contracts,
}: ApplyToJobExtra): GraphicContentObjProps => {
	const facImage =
		contracts.map((el) => el.facility.picture?.publicUrl).find((el) => el) ||
		FACILITY_PLACEHOLDER_IMAGE;
	const facNames = oxfordJoin(
		[...new Set(contracts.map((el) => el.facility.name))],
		'and',
	);
	return {
		img: getImageKitUrl(facImage, {
			transformation: [
				{
					// Double the max width of 370px
					// Ensure it is also a square for proper clipping
					width: '740',
					height: '740',
				},
			],
		}),
		imgSx: {
			maxWidth: 'min(370px, 100%)',
			clipPath: 'url(#facilityApplyClip)',
		},
		title: `Apply to EM practice${contracts.length > 1 ? 's' : ''}`,
		chip: facNames,
		subtitle: (
			<Box>
				<svg width='0' height='0'>
					<defs>
						{/* Notice we use objectBoundingBox and coords between (0, 0) and (1, 1) to make this scale down seamlessly*/}
						<clipPath id='facilityApplyClip' clipPathUnits='objectBoundingBox'>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M0.466422 0.00593807C0.588996 -0.00872524 0.632705 -0.000814502 0.729869 0.0825907C0.845045 0.181457 1.00208 0.271796 0.999979 0.432375C0.997884 0.592464 0.831913 0.694437 0.72051 0.797631C0.623216 0.887755 0.52843 0.988893 0.401475 0.99845C0.266389 1.00862 0.111515 0.969853 0.0319559 0.849464C-0.0429509 0.736114 0.0346032 0.599198 0.0550016 0.460922C0.0706711 0.354701 0.100526 0.271814 0.160929 0.187113C0.231641 0.0879572 0.352452 0.0195722 0.466422 0.00593807Z'
								fill='#C4C4C4'
							/>
						</clipPath>
					</defs>
				</svg>
				<Stack direction='column' spacing={3}>
					<Typography variant='body1' fontWeight='bold'>
						What's going to happen?
					</Typography>
					<Stack spacing={2}>
						<Stack direction='row' spacing={1}>
							<Typography variant='body2'>📬</Typography>
							<Typography variant='body2'>
								The recruiter will receive your job application and CV via
								email.
							</Typography>
						</Stack>
						<Stack direction='row' spacing={1}>
							<Typography variant='body2'>️✉️</Typography>
							<Typography variant='body2'>
								Check your email <b>(and junk folder)</b> over the next week for
								a reply from the recruiter.
							</Typography>
						</Stack>
						<Stack direction='row' spacing={1}>
							<Typography variant='body2'>📄</Typography>
							<Typography variant='body2'>
								We will also email you a copy of your application, where you can
								follow up with the recruiter directly.
							</Typography>
						</Stack>
					</Stack>
				</Stack>
			</Box>
		),
		buttonText: 'Continue',
	};
};

const steps: FormStepObj<ApplyToJobValues, ApplyToJobExtra>[] = [
	{
		key: 'message',
		title: 'Message',
		component: MessageAndCvStep,
		validate: messageAndCvValidation,
		initialValue: messageAndCvInitialValue,
		graphicContent: mainGraphic,
		finalStep: () => true,
		closeStep: () => true,
		submit,
	},
];

export { type ApplyToJobValues, type ApplyToJobExtra, steps };

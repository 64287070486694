import React from 'react';

import {
	Box,
	Grid,
	useTheme,
	useMediaQuery,
	type SxProps,
	type Theme,
	Stack,
	Typography,
} from '@mui/material';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { combineSx } from '@ivy/lib/styling/sx';
import { buildInternalLink } from '@ivy/lib/util/route';

import FavCard, { type FavCardDataObject } from './FavCard';

export interface BaseFavItem extends FavCardDataObject {
	id: string;
}

const FAVORITES_EMPTY_STATE_IMG =
	'https://assets.ivyclinicians.io/images/favorites-empty-state.svg';

interface FavoritesListProps {
	data: BaseFavItem[];
	onClick: (val: string) => void;
	sx?: SxProps<Theme>;
}

const ROW_SIZE = {
	xs: 1,
	sm: 2,
	md: 3,
};

const FavoritesList = ({ data, onClick, sx }: FavoritesListProps) => {
	const theme = useTheme();
	const isXs = useMediaQuery(theme.breakpoints.down('sm'));
	const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
	const breakpoint = isXs ? 'xs' : isSm ? 'sm' : 'md';

	return (
		<Box sx={combineSx({}, sx)}>
			<Typography variant='h2' component='h1'>
				Favorites
			</Typography>
			{data.length > 0 ? (
				<>
					<Typography variant='caption' sx={{ mb: 4 }}>
						{data.length} favorite{`${data.length > 1 ? 's' : ''}`}
					</Typography>
					<Grid
						container
						spacing={3}
						sx={{
							justifyContent: 'center',
						}}
					>
						{data.map((el) => (
							<Grid item key={el.id} xs={12} sm={6} md={4}>
								<FavCard data={el} onClick={() => onClick(el.id)} />
							</Grid>
						))}
						{[
							...new Array(
								ROW_SIZE[breakpoint] - (data.length % ROW_SIZE[breakpoint]),
							).keys(),
						].map((el, idx) => (
							<Grid
								item
								key={idx}
								xs={12}
								sm={6}
								md={4}
								sx={{
									padding: '0px !important',
								}}
							/>
						))}
					</Grid>
				</>
			) : (
				<>
					<Stack spacing={3} sx={{ maxWidth: '329px', mx: 'auto' }}>
						<Typography variant='h5' component='h2'>
							Oops! Nothing to see here
						</Typography>
						<Typography variant='caption'>
							You currently have{' '}
							<b>no residency programs marked as a favorite</b>. To mark as
							favorite,{' '}
							<RouteLink
								sx={{
									textDecorationColor: 'rgba(38, 130, 197, 0.4)',
									fontWeight: 'bold',
								}}
								to={buildInternalLink(RouteLink.routes.EMRA_SEARCH_INDEX, {
									type: 'residency',
									slug: 'search',
								})}
								underline='hover'
								color='light3.main'
							>
								search for a residency profile
							</RouteLink>
							, select a profile and hit the “Add to favorite” button in the top
							right corner.
						</Typography>
						<Box
							component='img'
							src={FAVORITES_EMPTY_STATE_IMG}
							sx={{
								width: '100%',
							}}
						/>
					</Stack>
				</>
			)}
		</Box>
	);
};

export default FavoritesList;

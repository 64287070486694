// TODO: update to show checkbox whenever there is a unconnected employer and
//  put the # of connected/pending nearby
import React from 'react';

import { TaskAlt } from '@mui/icons-material';
import { Checkbox, type SxProps, type Theme, Tooltip } from '@mui/material';

export interface MapItemCheckboxObject {
	processed?: boolean;
}

interface EntityCheckboxProps<T> {
	checked: boolean;
	onClick?: (ev: React.SyntheticEvent) => void;
	CheckboxComponent?: React.ComponentType<{
		sx?: SxProps<Theme>;
		checked?: boolean;
		onClick?: (ev: React.SyntheticEvent) => void;
	}>;
	WrapperComponent?: React.ComponentType<{
		sx?: SxProps<Theme>;
		children?: React.ReactNode;
	}>;
	entity: T;
}

const EntityCheckbox = <T extends MapItemCheckboxObject>({
	checked,
	onClick,
	entity,
	CheckboxComponent = Checkbox,
	WrapperComponent,
}: EntityCheckboxProps<T>) => {
	if (entity.processed) {
		// Connected to at least one employer
		// (Will need to connect to any others via the entity page)
		return (
			<Tooltip title={`You've applied to the employer(s) at this entity.`}>
				{WrapperComponent ? (
					<WrapperComponent
						sx={{
							borderRadius: '50%',
							width: '24px',
							height: '24px',
						}}
					>
						<TaskAlt
							sx={{
								color: 'secondary.main',
							}}
						/>
					</WrapperComponent>
				) : (
					<TaskAlt
						sx={{
							color: 'secondary.main',
						}}
					/>
				)}
			</Tooltip>
		);
	}

	// All employers are unconnected, show checkbox
	// Only shown right now if all are unconnected because, if you chose to only connect to a single employer
	// at a site and not another, there's probably a reason why
	return (
		<CheckboxComponent
			sx={{
				p: 0,
			}}
			checked={checked}
			onClick={onClick}
		/>
	);
};

export default EntityCheckbox;

import { createContext } from 'react';

import { type LocationState, type WhitelabelTo } from '@ivy/lib/util/route';

export interface RedirectOptions {
	openInNewTab?: boolean;
	state?: LocationState;
}

type RedirectContextValue = (
	to: WhitelabelTo,
	options?: Partial<RedirectOptions>,
) => Promise<void>;

export default createContext<RedirectContextValue>(undefined!);

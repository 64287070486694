import { type Location, type Path } from 'react-router-dom';

import { type BackNavConfig } from '@ivy/components/atoms/BackNavButton';
import config from '@ivy/config';
import WHITELABEL_CONFIGURATIONS, {
	type WhitelabelConfigName,
} from '@ivy/lib/whitelabel/whitelabelConfig';

export interface LocationState {
	backNav?: BackNavConfig;
}

export const populateLocationStateDefaults = (
	state: LocationState | null | undefined,
	location: Location,
	wl: WhitelabelConfigName,
) => {
	if (!state) {
		return state;
	}
	const newState = { ...state };
	if (newState.backNav && !newState.backNav.link) {
		newState.backNav.link = {
			pathname: location.pathname,
			whitelabel: wl,
		};
		if (location.search) {
			newState.backNav.link.search = location.search;
		}
		if (location.hash) {
			newState.backNav.link.hash = location.hash;
		}
	}
	return newState;
};

export interface WhitelabelPath extends Path {
	whitelabel?: WhitelabelConfigName;
	showSplashScreen?: boolean;
}

export type WhitelabelTo = Partial<WhitelabelPath> | string;

export const checkOutsideLink = (val: WhitelabelTo | string) => {
	if (typeof val === 'string') {
		// Empty string -> local
		return !!val && !val.startsWith('/');
	}
	if (val.whitelabel && val.whitelabel !== config.whitelabel) {
		return true;
	}
	if (!val.pathname) {
		// Empty path -> local
		return false;
	}
	return checkOutsideLink(val.pathname);
};

export const getOutsideTo = (to: WhitelabelTo | string) => {
	if (typeof to === 'string') {
		return to;
	}
	let prefix = '';
	if (to.whitelabel && to.whitelabel !== config.whitelabel) {
		prefix = WHITELABEL_CONFIGURATIONS.find(
			(el) => el.name === to.whitelabel,
		)!.baseUrl;
	}
	if (to.pathname) {
		let builder = prefix
			? `${prefix}${
					to.pathname.startsWith('/') ? to.pathname : `/${to.pathname}`
			  }`
			: to.pathname;
		if (to.search) {
			builder = `${builder}?${to.search}`;
		}
		// Fragment appears after query
		if (to.hash) {
			builder = `${builder}#${to.hash}`;
		}
		return builder;
	}
	return prefix;
};

const appendQueryToString = (val: string, qs: string) => {
	if (!qs) {
		return val;
	}
	const qsIdx = val.indexOf('?');
	return `${val}${qsIdx > -1 ? '&' : '?'}${qs}`;
};

const appendQueryToObj = (
	val: Partial<WhitelabelPath>,
	qs: string,
): Partial<WhitelabelPath> => {
	if (!qs) {
		return val;
	}
	if (val.search) {
		return {
			...val,
			search: `${val.search}&${qs}`,
		};
	} else {
		return {
			...val,
			search: qs,
		};
	}
};

export const appendQuery = (to: WhitelabelTo, qs: string): WhitelabelTo => {
	if (!qs) {
		return to;
	}
	if (typeof to === 'string') {
		return appendQueryToString(to, qs);
	}
	return appendQueryToObj(to as Path, qs);
};

export const reloadPage = async (clearCache = false) => {
	if (clearCache && 'caches' in window) {
		caches.keys().then((names) => {
			names.forEach(async (name) => {
				await caches.delete(name);
			});
		});
	}
	// window.location.reload(true) is deprecated but we still attempt anyway
	// @ts-ignore
	window.location.reload(clearCache);
};

export const getRelativeRoute = (path: string, relativeTo: string) => {
	return path.replace(relativeTo + '/', '');
};

/**
 * Creates an internal link from a route by filling in the params in a route.
 *
 * Example: `buildInternalLink('/facility/:facilityId/view', { facilityId: 'foo' })` -> `/facility/foo/view`
 *
 * @param route the route with the placeholder params to fill in
 * @param params a mapping of the params, as they appear in the route, to their concrete values. The value can either
 * be a string or an array of string, in which case the elements are joined by a hypen.
 */
export const buildInternalLink = (
	route: string,
	params: Record<string, string | string[]>,
) => {
	return Object.entries(params).reduce((tot, [k, v]) => {
		return tot.replaceAll(`:${k}`, Array.isArray(v) ? v.join('-') : v);
	}, route);
};

import React, { useState, useRef } from 'react';

import { ExpandMore } from '@mui/icons-material';
import { Button } from '@mui/material';

import QuickColumnPopover from '@ivy/components/molecules/QuickColumnButton/QuickColumnPopover';

const QuickColumnButton = ({
	onApply,
	onDiscard,
	initialValue,
	value,
	onChange,
	label,
	title,
	renderInput,
	badgeContent,
	footerComponent,
	hideFooter,
	sx,
	PopoverProps,
	...props
}) => {
	const anchorRef = useRef();
	const [open, setOpen] = useState(false);

	const handleChange = (nv) => {
		onChange({
			...value,
			...nv,
		});
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		onDiscard();
	};

	const handleReset = () => {
		onApply({
			...initialValue,
		});
		setOpen(false);
	};

	const handleApply = () => {
		onApply();
		setOpen(false);
	};

	return (
		<>
			<Button
				ref={anchorRef}
				variant='outlined'
				endIcon={<ExpandMore />}
				size='small'
				sx={[
					{
						color: 'text.primary',
						borderColor: 'divider',
						bgcolor: 'white',
						...(badgeContent
							? {
									color: 'primary.main',
									bgcolor: 'primary.translucent',
									borderColor: 'primary.main',
							  }
							: {}),
					},
					sx,
				]}
				onClick={handleOpen}
				{...props}
			>
				{label}
				{!!badgeContent && ` (${badgeContent})`}
			</Button>

			<QuickColumnPopover
				title={title}
				anchorEl={anchorRef.current}
				open={open}
				onClose={handleClose}
				onSubmit={handleApply}
				onReset={handleReset}
				footerComponent={footerComponent}
				hideFooter={hideFooter}
				{...PopoverProps}
			>
				{renderInput(value, handleChange)}
			</QuickColumnPopover>
		</>
	);
};

export default QuickColumnButton;
